<template>
	<div>
		<auth-container v-if="$route.name === 'InvitationList'" :page="page" require-admin>
			<h2 class="mb-4">Invitations</h2>

			<grid-view api-url="invitations" delete-api-url="invitations" use-filter
					   :fields="table.fields"
					   :default-sort="table.sort" :default-reverse="table.reverse"
					   collection-description="invitations" show-create
					   item-name="invitation"></grid-view>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'TopicList',
		components: {
			GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'conference', sortable: true },
						{ key: 'name', sortable: true },
						{ key: 'dateCreated', label: 'Invitation Date', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY h:mm a') } },
						{ key: 'delete', label: '' }
					],
					sort: 'dateCreated',
					reverse: true,
					itemsPerPage: 50
				}
			}
		}
	}
</script>