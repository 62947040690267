<template>
	<topic-form is-update :id="id"></topic-form>
</template>

<script>
	import TopicForm from '@/views/manage/topics/TopicForm';

	export default {
		name: 'TopicCreate',
		components: {
			TopicForm
		},
		data() {
			return {
				id: this.$route.params.id
			}
		}
	}
</script>