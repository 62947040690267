<template>
	<b-btn variant="secondary" @click="goBack">
		{{ text }}
	</b-btn>
</template>

<script>
export default {
	name: 'BackButton',
	props: {
		text: {
			type: String,
			default: 'Back'
		}
	},
	methods: {
		goBack: function() {
			this.$router.go(-1);
		}
	}
}
</script>
