<template>
	<auth-container :page="page" require-admin>
		<h2 class="mb-4">Add a new workshop</h2>
		<error-list :errors="page.saveErrors"></error-list>

		<b-form @submit.prevent="save">
			<b-form-group label="Workshop Name" :invalid-feedback="requiredFeedback($v.form.name)">
				<b-form-input v-model.trim="$v.form.name.$model" type="text" :state="getValidState($v.form.name)"></b-form-input>
			</b-form-group>

			<b-row>
				<b-col md>
					<b-form-group label="Start Date" :invalid-feedback="requiredFeedback($v.form.startDate)">
						<b-form-input v-model.trim="$v.form.startDate.$model" type="date" :state="getValidState($v.form.startDate)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="End Date" :invalid-feedback="requiredFeedback($v.form.endDate)">
						<b-form-input v-model.trim="$v.form.endDate.$model" type="date" :state="getValidState($v.form.endDate)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Website" :invalid-feedback="requiredFeedback($v.form.website)">
				<b-form-input v-model.trim="$v.form.website.$model" type="text" :state="getValidState($v.form.website)"></b-form-input>
			</b-form-group>

			<b-form-group label="Flywire Product Name" :invalid-feedback="requiredFeedback($v.form.productName)">
				<b-form-input v-model.trim="$v.form.productName.$model" type="text" :state="getValidState($v.form.productName)"></b-form-input>
			</b-form-group>

			<fixed-action-bar :cols-lg="10" :offset-lg="2">
				<save-button :saving="page.saving" class="mr-2" :disabled="!$v.$anyDirty" />
				<back-button class="btn btn-secondary mr-2" />
			</fixed-action-bar>
		</b-form>
	</auth-container>
</template>

<script>
	import { required, integer } from 'vuelidate/lib/validators';

	export default {
		name: 'WorkshopCreate',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: []
				},
				form: {
					name: null,
					website: null,
					startDate: null,
					endDate: null,
					productName: null
				}
			}
		},
		validations: {
			form: {
				name: { required },
				website: { required },
				startDate: { required },
				endDate: { required },
				productName: { required }
			}
		},
		methods: {
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						this.form.year = Number(this.form.year);

						const response = await this.$http.post('workshops', this.form, this.getTokenHeader());
						this.$router.push({ name: 'WorkshopEdit', params: { id: response.data } }).catch(err => { this.log(err); });
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			}
		}
	}
</script>