<template>
	<regparser-form></regparser-form>
</template>

<script>
	import RegparserForm from '@/views/manage/regparsers/RegparserForm';

	export default {
		name: 'RegparserCreate',
		components: {
			RegparserForm
		}
	}
</script>