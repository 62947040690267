<template>
	<auth-container :page="page" require-admin>
		<h2 class="mb-4">{{isUpdate ? 'Edit' : 'Add a new'}} registration CSV parser</h2>
		<error-list :errors="page.saveErrors"></error-list>
		<success-alert ref="savedAlert" text="Changes saved." />

		<b-form @submit.prevent="save">
			<b-form-group :invalid-feedback="requiredFeedback($v.form.conferenceId)">
				<b-form-select v-model="$v.form.conferenceId.$model" :options="options.conferences" @change="getPapers" :state="getValidState($v.form.conferenceId)">
					<template #first>
						<b-form-select-option :value="null" disabled>-- Select a conference --</b-form-select-option>
					</template>
				</b-form-select>
			</b-form-group>

			<b-form-group label="Name" :invalid-feedback="requiredFeedback($v.form.name)">
				<b-form-input v-model.trim="$v.form.name.$model" type="text" :state="getValidState($v.form.name)"></b-form-input>
			</b-form-group>

			<b-form-group label="Product Name" :invalid-feedback="requiredFeedback($v.form.productName)">
				<b-form-input v-model.trim="$v.form.productName.$model" type="text" :state="getValidState($v.form.productName)"></b-form-input>
			</b-form-group>

			<b-form-group label="Conference Registration Field" :invalid-feedback="requiredFeedback($v.form.conferenceRegField)">
				<b-form-input v-model.trim="$v.form.conferenceRegField.$model" type="text" :state="getValidState($v.form.conferenceRegField)"></b-form-input>
			</b-form-group>

			<b-form-group label="Workshop Registration Field" :invalid-feedback="requiredFeedback($v.form.workshopRegField)">
				<b-form-input v-model.trim="$v.form.workshopRegField.$model" type="text" :state="getValidState($v.form.workshopRegField)"></b-form-input>
			</b-form-group>

			<b-form-group label="Workshop Name Field" :invalid-feedback="requiredFeedback($v.form.workshopNameField)">
				<b-form-input v-model.trim="$v.form.workshopNameField.$model" type="text" :state="getValidState($v.form.workshopNameField)"></b-form-input>
			</b-form-group>

			<b-form-group label="Dinner Gala Field" :invalid-feedback="requiredFeedback($v.form.dinnerGalaField)">
				<b-form-input v-model.trim="$v.form.dinnerGalaField.$model" type="text" :state="getValidState($v.form.dinnerGalaField)"></b-form-input>
			</b-form-group>

			<b-form-group label="Additional Fields to Parse">
				<b-input-group>
					<b-form-input v-model="formData.additionalField" type="text"></b-form-input>
					<b-input-group-append>
						<b-button variant="primary" @click="addAdditionalField">Add</b-button>
					</b-input-group-append>
				</b-input-group>
			</b-form-group>
			<div>
				<b-button v-for="(s, i) in form.additionalFieldsToParse" :key="i"
						  @click="removeAdditionalField(s)" variant="light" size="sm" class="mr-2 my-1" v-b-tooltip.hover="'Click to remove from list'">
					{{s}}
					<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
				</b-button>
			</div>

			<fixed-action-bar :cols-lg="10" :offset-lg="2">
				<save-button :saving="page.saving" class="mr-2" :disabled="!$v.$anyDirty" />
				<back-button class="btn btn-secondary mr-2" />
				<b-button v-if="isUpdate" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete this topic?
				This action is permanent and cannot be undone.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</auth-container>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';

	export default {
		name: 'RegparserForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			id: {
				type: String,
				default: null
			}
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: [],
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				form: {
					conferenceId: null,
					name: null,
					productName: null,
					conferenceRegField: null,
					workshopRegField: null,
					workshopNameField: null,
					dinnerGalaField: null,
					additionalFieldsToParse: []
				},
				formData: {
					additionalField: null
				},
				options: {
					conferences: []
				}
			}
		},
		validations: {
			form: {
				conferenceId: { required },
				name: { required },
				productName: { required },
				conferenceRegField: { required },
				workshopRegField: { required },
				workshopNameField: { required },
				dinnerGalaField: { required },
				additionalFieldsToParse: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				try {
					const response = await this.$http.get(`invitations/conferences`, this.getTokenHeader());
					this.log(response.data);
					this.options.conferences = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				if (this.isUpdate) {
					this.page.errors = [];
					this.page.loading = true;

					try {
						const response = await this.$http.get(`registrationparsers/${this.id}`, this.getTokenHeader());
						this.log(response.data);
						this.form = response.data;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}

					this.page.loading = false;
				}
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						if (!this.isUpdate) {
							await this.$http.post('registrationparsers', this.form, this.getTokenHeader());
							this.$router.push({ name: 'RegparserList' }).catch(err => { this.log(err); });
						} else {
							await this.$http.put(`registrationparsers/${this.id}`, this.form, this.getTokenHeader());
							this.$refs.savedAlert.startAlert();
							this.$v.$reset();
						}
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`registrationparsers/${this.id}`, this.getTokenHeader());
					this.log(response.data);
					this.$router.push({ name: 'RegparserList' }).catch(err => { this.log(err); });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			addAdditionalField() {
				if (!this.isNullOrEmpty(this.formData.additionalField) && !this.form.additionalFieldsToParse.includes(this.formData.additionalField)) {
					this.form.additionalFieldsToParse.push(this.formData.additionalField);
				}
			},
			removeAdditionalField(value) {
				this.form.additionalFieldsToParse = this.form.additionalFieldsToParse.filter(function (el) { return el !== value; });
			}
		}
	}
</script>