<template>
	<div>
		<auth-container v-if="$route.name === 'PaperView'" :page="page">
			<h2 class="mb-4">View abstract</h2>
			<error-list :errors="page.resendApproval.errors"></error-list>
			<error-list :errors="page.resendSubmissionEmails.errors"></error-list>
			<success-alert ref="savedAlert" text="Changes saved." />

			<b-row>
				<b-col md>
					<b-table-simple table-class="table-definition border-bottom">
						<b-tbody>
							<b-tr>
								<b-th>Title:</b-th>
								<b-td>{{paper.title}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Authors:</b-th>
								<b-td><div v-html="authorHtml"></div></b-td>
							</b-tr>
							<b-tr>
								<b-th>Abstract:</b-th>
								<b-td><div v-html="paper.abstract"></div></b-td>
							</b-tr>
							<b-tr>
								<b-th>Keywords:</b-th>
								<b-td>{{paper.keywords}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
				<b-col md="5">
					<b-table-simple table-class="table-definition border-bottom">
						<b-tbody>
							<b-tr>
								<b-th>Status:</b-th>
								<b-td><b-badge :variant="paperStatusVariant" pill>{{paperStatusDescription}}</b-badge></b-td>
							</b-tr>
							<b-tr>
								<b-th>Submitted:</b-th>
								<b-td>{{paper.dateSubmitted | date}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Presenter:</b-th>
								<b-td>
									{{paper.presenterFirstName}} {{paper.presenterLastName}}
									<div><a :href="`mailto:${paper.presenterEmail}`">{{paper.presenterEmail}}</a></div>
									<div>{{paper.presenterAffiliation}}</div>
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>Topic:</b-th>
								<b-td>{{paper.topic}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Format:</b-th>
								<b-td>{{paperFormat}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Presentation:</b-th>
								<b-td>{{paperPresentation}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Access Code:</b-th>
								<b-td>{{paper.accessCode}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Registered?</b-th>
								<b-td>{{paper.isRegistered ? 'Yes' : ( paper.hasRegistrationExtension ? 'Discussed Extension' : 'No')}}</b-td>
							</b-tr>
							<template v-if="!isNullOrEmpty(paper.session)">
								<b-tr>
									<b-th>Session:</b-th>
									<b-td>{{paper.session}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Session Order:</b-th>
									<b-td>{{paper.orderInSession}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Schedule Confirmed:</b-th>
									<b-td>{{paper.isScheduleConfirmed ? 'Yes' : 'No'}}</b-td>
								</b-tr>
							</template>
							<template v-if="paper.status !== paperStatus.pending.value">
								<b-tr>
									<b-th>Reviewed on:</b-th>
									<b-td>{{paper.reviewedDate | date}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Reviewed by:</b-th>
									<b-td>{{paper.reviewer}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Reviewer notes:</b-th>
									<b-td>{{paper.reviewerNotes}}</b-td>
								</b-tr>
							</template>
						</b-tbody>
					</b-table-simple>
				</b-col>
			</b-row>

			<fixed-action-bar :cols-lg="10" :offset-lg="2">
				<back-button class="btn btn-secondary mr-2" />
				<b-button variant="primary" :to="`/print/${id}`" class="mr-2"><font-awesome-icon :icon="['fas', 'print']" /> Print</b-button>

				<b-button-group v-if="isAuthorized(roleNames.admin)" class="mr-2">
					<b-button variant="primary" to="edit" append><font-awesome-icon :icon="['fas', 'edit']" /> Edit</b-button>
					<save-button type="button" variant="info" v-if="paper.status === paperStatus.approved.value" :saving="page.resendApproval.saving" @click.native="resendApproval" text="Re-send Approval Email" />
					<save-button type="button" variant="info" v-if="isAuthorized(roleNames.admin) && paper.status === paperStatus.pending.value" :saving="page.resendSubmissionEmails.saving" @click.native="resendSubmissionEmails" text="Re-send Submission Emails" />
				</b-button-group>

				<b-button-group v-if="paper.canReview && (paper.status === paperStatus.pending.value || paper.status === paperStatus.sentBackForEdits.value)">
					<b-button variant="success" @click="page.approve.show = true"><font-awesome-icon :icon="['fas', 'check']" /> Approve</b-button>
					<b-button variant="danger" @click="isAuthorized(roleNames.admin) ? page.reject.show = true : page.pendingReject.show = true"><font-awesome-icon :icon="['fas', 'times']" /> Reject</b-button>
					<b-button variant="warning" @click="page.sendBack.show = true"><font-awesome-icon :icon="['fas', 'paper-plane']" /> Send Back for Edits</b-button>
				</b-button-group>

				<b-button-group v-if="isAuthorized(roleNames.admin) && paper.status === paperStatus.pendingRejected.value">
					<b-button variant="info" @click="page.reject.show = true">Finalize Rejection</b-button>
					<b-button variant="info" @click="page.approve.show = true">Approve (Cancel Rejection)</b-button>
					<b-button variant="warning" @click="page.sendBack.show = true"><font-awesome-icon :icon="['fas', 'paper-plane']" /> Send Back for Edits</b-button>
				</b-button-group>

				<b-button-group v-if="isAuthorized(roleNames.admin) && paper.status === paperStatus.rejected.value">
					<b-button variant="info" @click="page.approve.show = true">Approve (Cancel Rejection)</b-button>
					<b-button variant="warning" @click="page.sendBack.show = true"><font-awesome-icon :icon="['fas', 'paper-plane']" /> Send Back for Edits</b-button>
				</b-button-group>

				<b-button v-if="isAuthorized(roleNames.admin) && paper.status !== paperStatus.cancelled.value" variant="danger" @click="page.cancel.show = true" class="ml-auto mr-2">Cancel</b-button>
				<b-button v-if="isAuthorized(roleNames.admin) && paper.status === paperStatus.cancelled.value" variant="success" @click="page.setStatus.show = true" class="ml-auto mr-2">Update Status</b-button>
				<b-button v-if="isAuthorized(roleNames.admin)" variant="danger" @click="page.delete.show = true">Delete</b-button>
			</fixed-action-bar>

			<b-modal v-model="page.approve.show" size="lg" title="Approve this abstract?" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.approve.errors"></error-list>

				<p>
					An email will be sent to the presenter and corresponding author notifying them of the approval and next steps in the process.
				</p>

				<b-form-group label="Send additional comments to the submitter:">
					<b-form-textarea v-model="review.notes" rows="5"></b-form-textarea>
				</b-form-group>

				<div v-if="!paper.isPoster">
					<p>
						This paper was submitted as an <strong>oral presentation</strong>.
						If you think it should only be accepted as a <strong>poster</strong>
						instead, please check the box below. To avoid confusion with the presenter and organizing committee,
						please be sure to <strong>add comments about this change in the text area above</strong>.
					</p>
					<b-form-checkbox v-model="review.isPoster">Change to poster</b-form-checkbox>
				</div>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.approve.saving" @click.native="approve" text="Approve Paper" />
					<b-button type="button" variant="secondary" @click="page.approve.show = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.reject.show" size="lg" title="Reject this abstract?" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.reject.errors"></error-list>

				<p v-if="!isNullOrEmpty(paper.reviewer)">
					Originally reviewed by: {{paper.reviewer}}
				</p>

				<b-form-group label="Send additional comments to the submitter:">
					<b-form-textarea v-model="review.notes" rows="5"></b-form-textarea>
				</b-form-group>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.reject.saving" @click.native="reject" text="Reject Paper" />
					<b-button type="button" variant="secondary" @click="page.reject.show = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.pendingReject.show" size="lg" title="Reject this abstract?" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.pendingReject.errors"></error-list>

				<b-form-group label="Send additional comments to the submitter:">
					<b-form-textarea v-model="review.notes" rows="5"></b-form-textarea>
				</b-form-group>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.pendingReject.saving" @click.native="pendingReject" text="Reject Paper" />
					<b-button type="button" variant="secondary" @click="page.pendingReject.show = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.sendBack.show" size="lg" title="Send back to the submitted for changes?" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.sendBack.errors"></error-list>

				<p>
					If you think the user needs to improve their abstract before it is included in the conference,
					please detail what needs changing in the text area below.
				</p>

				<b-form-group label="Send additional comments to the submitter:">
					<b-form-textarea v-model="review.notes" rows="5"></b-form-textarea>
				</b-form-group>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.sendBack.saving" @click.native="sendBack" text="Send Back to User" />
					<b-button type="button" variant="secondary" @click="page.sendBack.show = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.cancel.show" size="md" title="Confirm paper cancellation" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.cancel.errors"></error-list>

				<p>
					Are you sure you want to cancel this paper?
					This will remove the paper from the accepted abstracts list and reset its assigned session (if applicable).
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.cancel.saving" @click.native="cancel" text="Cancel Paper" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.cancel.show = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to permanently delete this paper?
					In most cases, it is recommended to use the cancel action instead.
					We recommend deleting only when the submission is a duplicate of another paper.
					Deleting is permanent and cannot be recovered.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.setStatus.show" size="lg" title="Update paper's status" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.setStatus.errors"></error-list>

				<p>
					Note: updating the status will not notify the user. Please email separately if necessary.
				</p>

				<b-form-group>
					<b-form-select :options="paperStatus" v-model="page.setStatus.status">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>-- Select a new status for this paper --</b-form-select-option>
						</template>
					</b-form-select>
				</b-form-group>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.setStatus.saving" @click.native="setStatus" text="Update Status" />
					<b-button type="button" variant="secondary" @click="page.setStatus.show = false" class="ml-1">Close</b-button>
				</div>
			</b-modal>

		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'PaperView',
		data() {
			return {
				confId: this.$route.params.conf,
				id: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					approve: {
						errors: [],
						saving: false,
						show: false
					},
					reject: {
						errors: [],
						saving: false,
						show: false
					},
					pendingReject: {
						errors: [],
						saving: false,
						show: false
					},
					sendBack: {
						errors: [],
						saving: false,
						show: false
					},
					cancel: {
						errors: [],
						saving: false,
						show: false
					},
					delete: {
						errors: [],
						saving: false,
						show: false
					},
					setStatus: {
						errors: [],
						saving: false,
						show: false,
						status: null
					},
					resendApproval: {
						errors: [],
						saving: false
					},
					resendSubmissionEmails: {
						errors: [],
						saving: false
					}
				},
				paper: {
					authors: []
				},
				review: {
					notes: null,
					isPoster: false
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.confId = this.$route.params.conf;
				this.id = this.$route.params.id;

				try {
					const response = await this.$http.get(`papers/get/${this.confId}/${this.id}`, this.getTokenHeader());
					this.log(response.data);
					this.paper = response.data;
					this.review.isPoster = this.paper.isPoster;
					this.review.notes = this.paper.reviewerNotes;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async approve() {
				this.page.approve.errors = [];
				this.page.approve.saving = true;

				try {
					var data = {
						notice: this.review.notes,
						isPoster: this.review.isPoster
					}
					const response = await this.$http.put(`papers/approve/${this.id}`, data, this.getTokenHeader());
					this.log(response.data);
					this.page.approve.show = false;
					await this.get();
					this.$refs.savedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.approve.errors = this.logError(error);
				}

				this.page.approve.saving = false;
			},
			async pendingReject() {
				this.page.pendingReject.errors = [];
				this.page.pendingReject.saving = true;

				if (this.isNullOrEmpty(this.review.notes)) {
					this.page.pendingReject.errors.push("Please enter notes why the paper should be rejected.");
				} else {
					try {
						var data = {
							value: this.review.notes
						}
						const response = await this.$http.put(`papers/pendingreject/${this.id}`, data, this.getTokenHeader());
						this.log(response.data);
						this.page.pendingReject.show = false;
						await this.get();
						this.$refs.savedAlert.startAlert();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.pendingReject.errors = this.logError(error);
					}
				}

				this.page.pendingReject.saving = false;
			},
			async reject() {
				this.page.reject.errors = [];
				this.page.reject.saving = true;

				if (this.isNullOrEmpty(this.review.notes)) {
					this.page.sendBack.errors.push("Please enter notes why the paper should be rejected.");
				} else {
					try {
						var data = {
							value: this.review.notes
						}
						const response = await this.$http.put(`papers/reject/${this.id}`, data, this.getTokenHeader());
						this.log(response.data);
						this.page.reject.show = false;
						await this.get();
						this.$refs.savedAlert.startAlert();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.reject.errors = this.logError(error);
					}
				}

				this.page.reject.saving = false;
			},
			async sendBack() {
				this.page.sendBack.errors = [];
				this.page.sendBack.saving = true;

				if (this.isNullOrEmpty(this.review.notes)) {
					this.page.sendBack.errors.push("Please enter notes to the user about changes needed to their paper.");
				} else {
					try {
						var data = {
							value: this.review.notes
						}
						const response = await this.$http.put(`papers/sendback/${this.id}`, data, this.getTokenHeader());
						this.log(response.data);
						this.page.sendBack.show = false;
						await this.get();
						this.$refs.savedAlert.startAlert();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.sendBack.errors = this.logError(error);
					}
				}

				this.page.sendBack.saving = false;
			},
			async cancel() {
				this.page.cancel.errors = [];
				this.page.cancel.saving = true;

				try {
					const response = await this.$http.put(`papers/cancel/${this.id}`, {}, this.getTokenHeader());
					this.log(response.data);
					this.page.cancel.show = false;
					await this.get();
					this.$refs.savedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.cancel.errors = this.logError(error);
				}

				this.page.cancel.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`papers/${this.id}`, this.getTokenHeader());
					this.log(response.data);
					this.$router.push({ name: 'PaperList', params: { conf: this.confId } }).catch(err => { this.log(err); });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			async setStatus() {
				this.page.setStatus.errors = [];
				this.page.setStatus.saving = true;

				if (this.isNullOrEmpty(this.page.setStatus.status)) {
					this.page.setStatus.errors.push("Please select a new status for the paper.");
				} else {
					try {
						var data = {
							status: this.page.setStatus.status
						}
						const response = await this.$http.put(`papers/setstatus/${this.id}`, data, this.getTokenHeader());
						this.log(response.data);
						this.page.setStatus.show = false;
						await this.get();
						this.$refs.savedAlert.startAlert();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.setStatus.errors = this.logError(error);
					}
				}

				this.page.setStatus.saving = false;
			},
			async resendApproval() {
				this.page.resendApproval.errors = [];
				this.page.resendApproval.saving = true;

				try {
					const response = await this.$http.put(`papers/resendApproval/${this.id}`, {}, this.getTokenHeader());
					this.log(response.data);
					this.$refs.savedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.resendApproval.errors = this.logError(error);
				}

				this.page.resendApproval.saving = false;
			},
			async resendSubmissionEmails() {
				this.page.resendSubmissionEmails.errors = [];
				this.page.resendSubmissionEmails.saving = true;

				try {
					const response = await this.$http.put(`submit-abstract/resendEmails/${this.id}`, {}, this.getTokenHeader());
					this.log(response.data);
					this.$refs.savedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.resendSubmissionEmails.errors = this.logError(error);
				}

				this.page.resendSubmissionEmails.saving = false;
			}
		},
		computed: {
			paperStatusDescription() {
				var status = this.paper.status;
				var keys = Object.keys(this.paperStatus);
				this.log(status);
				this.log(keys);
				for (var i = 0; i < keys.length; i++) {
					if (this.paperStatus[keys[i]].value === status) return this.paperStatus[keys[i]].text;
				}
				return '';
			},
			paperStatusVariant() {
				var status = this.paper.status;
				var keys = Object.keys(this.paperStatus);
				for (var i = 0; i < keys.length; i++) {
					if (this.paperStatus[keys[i]].value === status) return this.paperStatus[keys[i]].variant;
				}
				return '';
			},
			paperFormat() {
				return this.paper.isPoster ? 'Poster' : 'Oral presentation';
			},
			paperPresentation() {
				return this.paper.isRemote ? 'Remote' : 'In-Person';
			},
			authorHtml() {
				var html = '<p class="authors mb-1">';
				var orderHtml = '<ol class="mb-1">';

				var cnt = this.paper.authors.length;
				var affiliationIndex = 1;
				for (var i = 0; i < cnt; i++) {
					var a = this.paper.authors[i];
					var comma = i === cnt - 1 ? '' : ', ';
					var corresponding = a.isCorresponding ? '*' : '';
					var affiliation = '';
					if (!this.isNullOrEmpty(a.affiliation) || a.isCorresponding) {
						affiliation = affiliationIndex;
						affiliationIndex++;

						var correspondingNote = a.isCorresponding ? `Email: ${a.email} (corresponding author)` : '';
						orderHtml += `<li>${a.affiliation} ${correspondingNote}</li >`;
					}

					html += `${a.firstName} ${a.lastName}<sup>${corresponding}${affiliation}</sup>${comma}`;
				}
				html += '</p>';
				orderHtml += '</ol>';

				return html + orderHtml;
			}
		}
	}
</script>