<template>
	<div id="content-frame">
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<error-list v-if="page.errors.length > 0" :errors="page.errors"></error-list>
			<div v-else-if="page.submitted" class="py-5">
				<b-container>
					<div class="jumbotron">
						<h1>Thank you for your submission!</h1>
						<p class="lead">
							Confirmation access code: <span class="text-danger">{{page.code}}</span>
						</p>
						<p class="lead">
							We have emailed you a confirmation of your submission. Be sure to add <a :href="`mailto:${contactEmail}`">{{contactEmail}}</a> to your
							safe-senders list, and check your junk mail if you do not receive a message soon. All communication will be done from this email address, so we want to ensure
							that our messages are reaching you.
						</p>
						<p class="lead">
							Our scientific committee will review your abstract and try to notify you within a few weeks if your paper is accepted or rejected.
							If your paper is accepted, the presenter must <strong style="text-decoration:underline">register</strong> for the conference <em>before</em> being
							issued an invitation letter for a visa or being included in the agenda.
							Try to use the same email when you register so our system can detect your registration status.
						</p>
						<div>
							<b-button type="button" variant="primary" size="lg" :href="conference.website" class="mr-2">Visit the conference website</b-button>
							<b-button type="button" variant="light" size="lg" @click="reload" class="border">Submit another abstract</b-button>
						</div>
					</div>
				</b-container>
			</div>
			<div v-else>
				<div class="bg-gradient-light-blue py-5">
					<b-container>
						<h1>{{conference.name}}</h1>
						<p class="lead">
							Use the form below to <strong>submit an abstract</strong> for presentation at the conference.
							After you submit the abstract, our scientific committee will review it and try to notify you within a few weeks if your paper is accepted or rejected.
							If your paper is accepted, the presenter must <strong style="text-decoration:underline">register</strong> for the conference <em>before</em> being issued an invitation
							letter for a visa or being included in the agenda.
							Visit the <a :href="conference.website" target="_blank">conference website</a> for more information.
						</p>
					</b-container>
				</div>
				<div class="py-5">
					<b-container v-if="conference.isClosed">
						<p>
							<span class="text-danger">Abstract submission is closed.</span>
							However, if you have been pre-approved for a late submission please enter
							the access code below:
						</p>

						<error-list :errors="page.saveErrors"></error-list>

						<b-form inline @submit.prevent="verify">
							<label class="sr-only" for="lateAccessCode">Access code</label>
							<b-form-input id="lateAccessCode" v-model="access.code" placeholder="Enter your access code" type="password"></b-form-input>
							<save-button :saving="page.saving" class="ml-2" :disabled="isNullOrEmpty(access.code)" text="Submit" />
						</b-form>
					</b-container>
					<b-container v-else-if="access.showDisclaimer">
						<div class="jumbotron">
							<p class="lead">
								Please read and agree to the following before continuing to the form:
							</p>
							<ol class="lead">
								<li>Please use <strong>proper capitalization</strong>; do not use all capital or all lowercase letters for names and titles.</li>
								<li>Only the person presenting the paper must register for the conference.</li>
								<li>Try to use the same email when you register so our system can detect your registration status.</li>
								<li>Let us know of any restrictions for scheduling your presentation at least one month in advance.</li>
							</ol>

							<b-button type="button" @click="acceptDisclaimer" variant="primary" size="lg">Agree and continue</b-button>
						</div>
					</b-container>
					<b-container v-else>
						<b-form @submit.prevent="preview">
							<error-list :errors="page.saveErrors"></error-list>

							<h2 class="h4">Who will present at the conference?</h2>
							<p>
								Please enter the name and email address of the person who will present the paper at the conference.
								You can change this information later if needed.
							</p>
							<b-row>
								<b-col md>
									<b-form-group label="First name (given name)" :invalid-feedback="requiredFeedback($v.form.presenterFirstName)">
										<b-form-input autofocus v-model.trim="$v.form.presenterFirstName.$model" type="text" :state="getValidState($v.form.presenterFirstName)"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col md>
									<b-form-group label="Last name (family/surname)" :invalid-feedback="requiredFeedback($v.form.presenterLastName)">
										<b-form-input v-model.trim="$v.form.presenterLastName.$model" type="text" :state="getValidState($v.form.presenterLastName)"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col md>
									<b-form-group label="Email address">
										<b-form-input v-model.trim="$v.form.presenterEmail.$model" type="email" :state="getValidState($v.form.presenterEmail)"></b-form-input>
										<b-form-invalid-feedback v-if="!$v.form.presenterEmail.required">Required</b-form-invalid-feedback>
										<b-form-invalid-feedback v-if="!$v.form.presenterEmail.email">Please enter a valid email address</b-form-invalid-feedback>
									</b-form-group>
								</b-col>
								<b-col md>
									<b-form-group label="Affiliation (organization or university)" :invalid-feedback="requiredFeedback($v.form.presenterAffiliation)">
										<b-form-input v-model.trim="$v.form.presenterAffiliation.$model" type="text" :state="getValidState($v.form.presenterAffiliation)"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>

							<div class="mt-4 mb-2">
								Is the presenter an author for the paper? Click the button below to
								copy the information above to the section below.
							</div>
							<div>
								<b-button type="button" @click="copyPresenter" variant="primary">Copy presenter information</b-button>
							</div>

							<hr class="my-4" />

							<h2 class="h4">Authors</h2>

							<p>
								Please add all authors for your paper in the correct <abbr title="Click and drag a row to sort">order</abbr>, including the presenter above if applicable.
								(To remove, just clear the text from the blanks.)
							</p>

							<p>
								CA = Corresponding author; email address is required for the corresponding author, but optional for other authors.
							</p>

							<table class="table table-striped table-sm table-responsive table-valign-m">
								<thead class="thead-light">
									<tr>
										<th></th>
										<th><abbr title="Corresponding author">CA</abbr></th>
										<th>First name (given name)</th>
										<th>Last name (family/surname)</th>
										<th style="width:20rem">Email (required for CA)</th>
										<th style="width:25rem">Affiliation (optional)</th>
									</tr>
								</thead>
								<draggable v-model="form.authors" tag="tbody">
									<tr v-for="(a, i) in form.authors" :key="i">
										<td><font-awesome-icon :icon="['fas', 'sort']" fixed-width /></td>
										<td><b-checkbox v-model="a.isCorresponding"></b-checkbox></td>
										<td><b-form-input v-model="a.firstName" type="text"></b-form-input></td>
										<td><b-form-input v-model="a.lastName" type="text"></b-form-input></td>
										<td><b-form-input v-model="a.email" type="email"></b-form-input></td>
										<td><b-form-input v-model="a.affiliation" type="text"></b-form-input></td>
									</tr>
								</draggable>
							</table>

							<div>
								<b-button type="button" @click="addAuthor" variant="primary">Add an author</b-button>
							</div>

							<hr class="my-4" />

							<h2 class="h4 mb-3">Paper information</h2>

							<b-form-group :invalid-feedback="requiredFeedback($v.form.topicId)">
								<b-form-select :options="conference.topics" v-model.trim="$v.form.topicId.$model" type="text" :state="getValidState($v.form.topicId)">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>-- Select a topic for your paper --</b-form-select-option>
									</template>
								</b-form-select>
							</b-form-group>

							<b-form-group label="Title" :invalid-feedback="requiredFeedback($v.form.title)">
								<b-form-input v-model.trim="$v.form.title.$model" type="text" :state="getValidState($v.form.title)"></b-form-input>
							</b-form-group>

							<b-form-group label="Type or paste your abstract" :invalid-feedback="requiredFeedback($v.form.abstract)">
								<tinymce-editor :api-key="tinymce.api" :init="tinymce.init" v-model.trim="$v.form.abstract.$model" :state="getValidState($v.form.abstract)"></tinymce-editor>
								<b-form-invalid-feedback v-if="!$v.form.abstract.required">Required</b-form-invalid-feedback>
							</b-form-group>

							<b-form-group label="Keywords (optional)" :invalid-feedback="requiredFeedback($v.form.keywords)">
								<b-form-input v-model.trim="$v.form.keywords.$model" type="text" :state="getValidState($v.form.keywords)"></b-form-input>
							</b-form-group>

							<hr class="my-4" />

							<h2 class="h4">Scheduling</h2>

							<p>
								Oral presentations are organized into parallel sessions spread over 3 days
								({{conference.startDate | date('MMMM Do')}} to {{conference.endDate | date('MMMM Do')}}).
								Space in the first and second day is limited, and we appreciate if you are open to presenting on the
								last day. Oral presentations should be 15-20 minutes long.
							</p>
							<p>
								Posters are shown in a single dedicated session.
								Recommended poster size will be sent at a later time along with the schedule.
							</p>
							<p>
								<strong>Please select if you would prefer an oral or poster presentation.</strong>
								If oral is selected, the scientific committee may decide the work is better presented as a poster.
							</p>

							<b-form-radio-group v-model="form.isPoster" buttons button-variant="outline-primary" size="lg" class="present-formats">
								<b-form-radio :value="false"><font-awesome-icon v-if="!form.isPoster" :icon="['fas', 'check']" /> Oral</b-form-radio>
								<b-form-radio :value="true"><font-awesome-icon v-if="form.isPoster" :icon="['fas', 'check']" /> Poster</b-form-radio>
							</b-form-radio-group>

							<p class="mt-4" v-if="conference.disableRemotePresentation">
								<strong>This conference will be IN-PERSON ONLY</strong>. Remote presentation is not possible at this conference. 
								Please only submit an abstract if you plan to attend in person.
							</p>

							<p class="mt-4" v-else-if="form.isPoster">
								<strong>Posters may only be presented IN-PERSON</strong>.
								Please only submit an abstract for poster presentation if you plan to attend in person.
							</p>

							<p class="mt-4" v-else>
								<strong>Do you plan to attend the conference in person or participate remotely?</strong>
								We plan to hold remote sessions each day in parallel with in-person sessions.
								Please keep in mind the conference location and timezone.
								Note any concerns in the scheduling restrictions field below.
							</p>

							<b-form-radio-group v-model="form.isRemote" buttons button-variant="outline-primary" size="lg" class="present-formats">
								<b-form-radio :value="false"><font-awesome-icon v-if="!form.isRemote" :icon="['fas', 'check']" /> In-Person</b-form-radio>
								<b-form-radio :value="true" 
									v-if="!conference.disableRemotePresentation && !form.isPoster"><font-awesome-icon v-if="form.isRemote" :icon="['fas', 'check']" /> Remote</b-form-radio>
							</b-form-radio-group>

							<p class="mt-4">
								<strong>Do you have any scheduling restrictions?</strong>
								Please list any days and times you are unable to present.
								Leave blank if you have no conflicts.
							</p>

							<div>
								<b-form-textarea rows="3" v-model.trim="$v.form.scheduleRequests.$model" :state="getValidState($v.form.scheduleRequests)" placeholder="(e.g., I cannot present Friday)"></b-form-textarea>
							</div>

							<p>
								We cannot guarantee that we can meet every schedule request, but we will do our best.
								The more we know in advance, the better we can accommodate you.
							</p>

							<hr class="my-4" />

							<p v-if="$v.$anyDirty">
								Everything look good? Click the review button below to preview your submission before sending it
								to the committee.
							</p>
							<p v-else class="text-danger">
								Please complete all required fields.
							</p>

							<error-list :errors="page.saveErrors"></error-list>

							<save-button :saving="page.saving" :disabled="!$v.$anyDirty" text="Review Abstract Submission" size="lg" />
						</b-form>
					</b-container>

					<b-modal v-model="page.showPreview" size="xl" title="Abstract Submission" scrollable>
						<error-list :errors="page.saveErrors"></error-list>

						<b-alert variant="info" show>
							Please review your entry. If everything looks correct, click the Submit Abstract button
							to send your abstract to the scientific committee for review.
							Otherwise, click cancel to return to the form and make changes.
						</b-alert>

						<h5>Presenter</h5>
						<p>
							{{form.presenterFirstName}} {{form.presenterLastName}}, {{form.presenterAffiliation}}
							({{form.presenterEmail}})
						</p>

						<h5>Topic</h5>
						<p>
							{{selectedTopic}}
						</p>

						<h5>Scheduling</h5>
						<p>
							{{selectedFormat}}
							<br />{{selectedParticipation}}
							<br />{{isNullOrEmpty(form.scheduleRequests) ? 'No schedule restrictions' : form.scheduleRequests}}
						</p>

						<hr class="my-4" />

						<h5>{{form.title}}</h5>
						<div v-html="authorHtml"></div>

						<h6>Abstract</h6>
						<div v-html="form.abstract"></div>

						<h6>Keywords</h6>
						<p>{{form.keywords}}</p>

						<div slot="modal-footer">
							<save-button type="button" :saving="page.saving" :disabled="!$v.$anyDirty" text="Submit Abstract" @click.native="save" />
							<b-button type="button" variant="secondary" @click="page.showPreview = false" class="ml-1">Cancel</b-button>
						</div>
					</b-modal>
				</div>
			</div>
		</div>
		<footer-data></footer-data>
	</div>
</template>

<script>
	import { required, email, integer } from 'vuelidate/lib/validators';
	import draggable from 'vuedraggable';
	import Editor from '@tinymce/tinymce-vue';

	export default {
		name: 'SubmitAbstract',
		components: {
			draggable, 'tinymce-editor': Editor
		},
		data() {
			return {
				id: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					saving: false,
					saveErrors: [],
					showPreview: false,
					submitted: false,
					code: null
				},
				conference: {
					topics: []
				},
				access: {
					code: null,
					showDisclaimer: true
				},
				form: {
					presenterFirstName: null,
					presenterLastName: null,
					presenterEmail: null,
					presenterAffiliation: null,
					topicId: null,
					title: null,
					authors: [
						{
							firstName: null,
							lastName: null,
							email: null,
							affiliation: null,
							isCorresponding: false
						}
					],
					abstract: null,
					keywords: null,
					isPoster: false,
					isRemote: false,
					scheduleRequests: null
				},
				hasCopiedPresenter: false,
				options: {
					formats: [
						{ text: 'Oral', value: false },
						{ text: 'Poster', value: true }
					]
				},
				tinymce: {
					api: 'umc6fcf2lmc4vpzyrqb2jfcr2slzvqb94kk4ac6wl757gx5b',
					init: {
						height: 400,
						menubar: false,
						plugins: [
							'advlist autolink lists link image charmap print preview anchor',
							'searchreplace visualblocks code fullscreen',
							'insertdatetime media table paste code help wordcount'
						],
						toolbar: 'undo redo | formatselect | bold italic underline | link unlink | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code',
					}
				}
			}
		},
		validations: {
			form: {
				presenterFirstName: { required },
				presenterLastName: { required },
				presenterEmail: { required, email },
				presenterAffiliation: { required },
				topicId: { required, integer },
				title: { required },
				abstract: { required },
				keywords: {},
				isPoster: {},
				isRemote: {},
				scheduleRequests: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.id = this.$route.params.id;

				try {
					const response = await this.$http.get(`submit-abstract/${this.id}`);
					this.log(response.data);
					this.conference = response.data;
				} catch (error) {
					this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async verify() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					var data = {
						value: this.access.code
					}
					const response = await this.$http.post(`submit-abstract/verify/${this.id}`, data);

					if (response.data.valid) {
						this.conference.isClosed = false;
					} else {
						this.conference.isClosed = true;
						this.page.saveErrors.push('Invalid access code.');
					}
				} catch (error) {
					this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			async preview() {
				this.page.saveErrors = [];
				this.page.saving = true;

				var authorErrors = this.checkAuthors();

				if (this.conference.disableRemotePresentation || this.form.isPoster) {
					this.form.isRemote = false;
				}

				this.log(this.form);

				if (authorErrors.length > 0) {
					this.page.saveErrors = authorErrors;
				} else if (this.$v.$invalid) {
					this.page.saveErrors.push('Please scroll through the form and fix any errors then try again.');
				} else {
					this.page.showPreview = true;
				}

				this.page.saving = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please scroll through the form and fix any errors then try again.');
				} else {
					this.log(this.form);
					try {
						const response = await this.$http.post(`submit-abstract/${this.id}`, this.form);
						this.log(response.data);

						this.page.submitted = true;
						this.page.code = response.data;
						this.page.showPreview = false;
					} catch (error) {
						this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			copyPresenter() {
				if (!this.hasCopiedPresenter) {
					this.form.authors.unshift({
						firstName: this.form.presenterFirstName,
						lastName: this.form.presenterLastName,
						email: this.form.presenterEmail,
						affiliation: this.form.presenterAffiliation,
						isCorresponding: true
					});
					this.hasCopiedPresenter = true;
				}
			},
			addAuthor() {
				this.form.authors.push({
					firstName: null,
					lastName: null,
					email: null,
					affiliation: null,
					isCorresponding: false
				});
			},
			acceptDisclaimer() {
				this.access.showDisclaimer = false;
			},
			checkAuthors() {
				var errors = [];

				for (var i = 0; i < this.form.authors.length; i++) {
					var a = this.form.authors[i];
					if (this.isNullOrEmpty(a.firstName) && this.isNullOrEmpty(a.lastName)) {
						this.form.authors.splice(i, 1);
						this.log(i);
					} else if (a.isCorresponding && this.isNullOrEmpty(a.email)) {
						errors.push('Please provide an email address for the corresponding author.')
					}
				}

				if (this.form.authors.length < 1) {
					errors.push('Please enter at least one author for the paper.');
				}

				return errors;
			},
			reload() {
				window.location.reload(true);
			}
		},
		computed: {
			selectedTopic() {
				var topicId = this.form.topicId;
				var matches = this.conference.topics.filter(function (el) { return el.value === topicId; });
				if (matches.length > 0) return matches[0].text;
				return '';
			},
			selectedFormat() {
				return this.form.isPoster ? 'Poster' : 'Oral presentation';
			},
			selectedParticipation() {
				return this.form.isRemote ? 'Remote' : 'In-Person';
			},
			authorHtml() {
				var html = '<p class="authors">';
				var orderHtml = '<ol>';

				var cnt = this.form.authors.length;
				var affiliationIndex = 1;
				for (var i = 0; i < cnt; i++) {
					var a = this.form.authors[i];
					var comma = i === cnt - 1 ? '' : ', ';
					var corresponding = a.isCorresponding ? '*' : '';
					var affiliation = '';
					if (!this.isNullOrEmpty(a.affiliation) || a.isCorresponding) {
						affiliation = affiliationIndex;
						affiliationIndex++;

						var correspondingNote = a.isCorresponding ? `Email: ${a.email} (corresponding author)` : '';
						orderHtml += `<li>${a.affiliation} ${correspondingNote}</li >`;
					}

					html += `${a.firstName} ${a.lastName}<sup>${corresponding}${affiliation}</sup>${comma}`;
				}
				html += '</p>';
				orderHtml += '</ol>';

				return html + orderHtml;
			}
		}
	}
</script>
