<template>
	<footer class="container-fluid bg-gradient-primary text-white py-4">
		<div class="container text-center">
			<p>
				For help using this website, contact us at <a :href="`mailto:${contactEmail}`">{{contactEmail}}</a>.
			</p>
			<hr class="my-4">
			<p class="logos my-4">
				<a href="http://www.tamu.edu"><img :src="`${publicPath}web-images/tamu.png`" alt="TAMU Logo" /></a>
				<a href="http://agriliferesearch.tamu.edu/"><img :src="`${publicPath}web-images/agrilife-research.png`" alt="AgriLife Research Logo" /></a>
				<a href="http://www.ars.usda.gov"><img :src="`${publicPath}web-images/ars.png`" alt="ARS Logo" /></a>
			</p>

			<ul class="req-links">
				<li><a href="http://agrilife.org/required-links/compact/">Compact with Texans</a></li>
				<li><a href="http://agrilife.org/required-links/privacy/">Privacy and Security</a></li>
				<li><a href="http://itaccessibility.tamu.edu/">Accessibility Policy</a></li>
				<li><a href="http://publishingext.dir.texas.gov/portal/internal/resources/DocumentLibrary/State%20Website%20Linking%20and%20Privacy%20Policy.pdf">State Link Policy</a></li>
				<li><a href="http://www.tsl.state.tx.us/trail">Statewide Search</a></li>
				<li><a href="http://veterans.tamu.edu/">Veterans Benefits</a></li>
				<li><a href="http://fcs.tamu.edu/families/military_families/">Military Families</a></li>
				<li><a href="https://secure.ethicspoint.com/domain/en/report_custom.asp?clientid=19681">Risk, Fraud &amp; Misconduct Hotline</a></li>
				<li><a href="http://www.texashomelandsecurity.com/">Texas Homeland Security</a></li>
				<li><a href="http://veterans.portal.texas.gov/">Texas Veteran&apos;s Portal</a></li>
				<li><a href="http://aghr.tamu.edu/education-civil-rights.htm">Equal Opportunity</a></li>
				<li><a href="http://agrilife.org/required-links/orpi/">Open Records/Public Information</a></li>
			</ul>

			<p>
				&copy; {{currentYear}} All rights reserved.
			</p>
		</div>
	</footer>
</template>

<script>
	import moment from 'moment';

	export default {
		name: 'FooterData',
		computed: {
			currentYear() {
				return moment().year();
			}
		}
	}
</script>
