<template>
	<auth-container :page="page" require-admin>
		<h2 class="mb-4">Edit certificate</h2>

		<error-list :errors="page.saveErrors"></error-list>
		<success-alert ref="savedAlert" text="Changes saved." />

		<b-form @submit.prevent="save">
			<b-form-group :invalid-feedback="requiredFeedback($v.form.conferenceId)">
				<b-form-select v-model="$v.form.conferenceId.$model" :options="options.conferences" :state="getValidState($v.form.conferenceId)">
					<template v-slot:first>
						<option :value="null" disabled>-- Select a conference --</option>
					</template>
				</b-form-select>
			</b-form-group>

			<b-form-group label="Full Name" :invalid-feedback="requiredFeedback($v.form.name)">
				<b-form-input v-model.trim="$v.form.name.$model" type="text" :state="getValidState($v.form.name)"></b-form-input>
			</b-form-group>

			<b-form-group label="Email" :invalid-feedback="requiredFeedback($v.form.email)">
				<b-form-input v-model.trim="$v.form.email.$model" type="email" :state="getValidState($v.form.email)"></b-form-input>
			</b-form-group>

			<b-form-group label="Alternative Email" :invalid-feedback="requiredFeedback($v.form.altEmail)">
				<b-form-input v-model.trim="$v.form.altEmail.$model" type="email" :state="getValidState($v.form.altEmail)"></b-form-input>
			</b-form-group>

			<b-form-group label="Workshop Name (if applicable)" :invalid-feedback="requiredFeedback($v.form.workshopName)">
				<b-form-input v-model.trim="$v.form.workshopName.$model" type="text" :state="getValidState($v.form.workshopName)"></b-form-input>
			</b-form-group>

			<b-form-group>
				<b-checkbox v-model.trim="$v.form.wasConferenceAttendee.$model">Was conference attendee</b-checkbox>
			</b-form-group>
			<b-form-group>
				<b-checkbox v-model.trim="$v.form.wasWorkshopAttendee.$model">Was workshop attendee</b-checkbox>
			</b-form-group>
			<b-form-group>
				<b-checkbox v-model.trim="$v.form.wasPresenter.$model">Was presenter</b-checkbox>
			</b-form-group>

			<fixed-action-bar :cols-lg="10" :offset-lg="2">
				<router-link :to="`/get-certificates/${id}`" class="btn btn-info mr-2" target="_blank">Get Certificates</router-link>

				<save-button :saving="page.saving" class="mr-2" :disabled="!$v.$anyDirty" />
				<back-button class="btn btn-secondary mr-2" />
			</fixed-action-bar>
		</b-form>
	</auth-container>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';

	export default {
		name: 'CertificateEdit',
		data() {
			return {
				id: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: []
				},
				form: {
					conferenceId: null,
					name: null,
					email: null,
					altEmail: null,
					wasConferenceAttendee: false,
					wasWorkshopAttendee: false,
					wasPresenter: false,
					workshopName: null
				},
				options: {
					conferences: []
				}
			}
		},
		validations: {
			form: {
				conferenceId: { required },
				name: { required },
				email: { required },
				altEmail: {},
				wasConferenceAttendee: {},
				wasWorkshopAttendee: {},
				wasPresenter: {},
				workshopName: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.id = this.$route.params.id;

				try {
					const confResponse = await this.$http.get(`invitations/conferences`, this.getTokenHeader());
					this.options.conferences = confResponse.data;

					const response = await this.$http.get(`certificates/${this.id}`, this.getTokenHeader());
					this.log(response.data);
					this.form = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.put(`certificates/${this.id}`, this.form, this.getTokenHeader());
						this.log(response.data);
						this.$refs.savedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			}
		}
	}
</script>