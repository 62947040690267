<template>
	<div>
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<login-form v-if="!disabled && (!isAuthenticated || page.showLogin || !isAuthorized(roleNames.user))" show-header></login-form>
			<div v-else-if="!disabled && requireAdmin && !isAuthorized(roleNames.admin)">
				<b-alert variant="warning" show>
					You are not authorized to view this page.
				</b-alert>
			</div>
			<error-list v-else-if="!disabled && page.errors.length > 0 && !hideErrors" :errors="page.errors"></error-list>
			<div v-else>
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AuthContainer',
		props: {
			page: {
				type: Object,
				required: true
			},
			requireAdmin: {
				type: Boolean,
				default: false
			},
			hideErrors: {
				type: Boolean,
				default: false
			},
			disabled: {
				type: Boolean,
				default: false
			}
		}
	}
</script>
