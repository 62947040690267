<template>
	<auth-container :page="page" require-admin>
		<h2 class="mb-4">Create a new invitation</h2>
		<error-list :errors="page.saveErrors"></error-list>

		<b-form @submit.prevent="save">
			<b-form-group :invalid-feedback="requiredFeedback($v.form.conferenceId)">
				<b-form-select v-model="$v.form.conferenceId.$model" :options="options.conferences" @change="getPapers" :state="getValidState($v.form.conferenceId)">
					<template v-slot:first>
						<option :value="null" disabled>-- Select a conference --</option>
					</template>
				</b-form-select>
			</b-form-group>

			<page-loading :loading="page.papersLoading"></page-loading>
			<b-form-group v-if="show.papers" description="(Y) = registered; (N) = not registered">
				<b-form-select v-model="options.paperId" :options="options.papers" @change="getTemplate">
					<template v-slot:first>
						<option :value="null" disabled>-- Select a presenter --</option>
						<option :value="0">None</option>
					</template>
				</b-form-select>
			</b-form-group>

			<page-loading :loading="page.templateLoading"></page-loading>
			<div v-if="show.form">
				<b-form-group label="Invitee's Full Name" :invalid-feedback="requiredFeedback($v.form.name)">
					<b-form-input v-model.trim="$v.form.name.$model" type="text" :state="getValidState($v.form.name)"></b-form-input>
				</b-form-group>

				<b-form-group label="Invitee's Contact Information" :invalid-feedback="requiredFeedback($v.form.contact)">
					<b-form-textarea v-model.trim="$v.form.contact.$model" rows="5" :state="getValidState($v.form.contact)"></b-form-textarea>
				</b-form-group>

				<b-form-group label="Letter Contents" :invalid-feedback="requiredFeedback($v.form.contents)">
					<b-form-textarea v-model.trim="$v.form.contents.$model" rows="10" :state="getValidState($v.form.contents)"></b-form-textarea>
				</b-form-group>
			</div>

			<fixed-action-bar :cols-lg="10" :offset-lg="2">
				<save-button :saving="page.saving" class="mr-2" :disabled="!$v.$anyDirty || !show.papers || !show.form" />
				<back-button class="btn btn-secondary mr-2" />
			</fixed-action-bar>
		</b-form>
	</auth-container>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';

	export default {
		name: 'InvitationCreate',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: [],
					papersLoading: false,
					templateLoading: false
				},
				form: {
					conferenceId: null,
					name: null,
					contact: null,
					contents: null
				},
				options: {
					conferences: [],
					papers: [],
					paperId: null
				},
				show: {
					papers: false,
					form: false
				}
			}
		},
		validations: {
			form: {
				conferenceId: { required },
				name: { required },
				contact: { required },
				contents: { required }
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`invitations/conferences`, this.getTokenHeader());
					this.log(response.data);
					this.options.conferences = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async getPapers() {
				this.page.saveErrors = [];
				this.page.papersLoading = true;
				this.show.papers = false;
				this.show.form = false;

				if (this.isNullOrEmpty(this.form.conferenceId)) {
					this.page.papersErrors.push('Please select a conference.');
				} else {
					try {
						const response = await this.$http.get(`invitations/papers/${this.form.conferenceId}`, this.getTokenHeader());
						this.log(response.data);
						this.options.papers = response.data;
						this.show.papers = true;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.papersLoading = false;
			},
			async getTemplate() {
				this.page.saveErrors = [];
				this.page.templateLoading = true;
				this.show.form = false;

				if (this.isNullOrEmpty(this.form.conferenceId)) {
					this.page.papersErrors.push('Please select a conference.');
				} else {
					try {
						let action = `invitations/template/${this.options.paperId}`;
						if (this.options.paperId === 0) action = `invitations/conferencetemplate/${this.form.conferenceId}`;

						const response = await this.$http.get(action, this.getTokenHeader());
						this.log(response.data);
						this.form.name = response.data.name;
						this.form.contact = response.data.contact;
						this.form.contents = response.data.contents;
						this.show.form = true;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.templateLoading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.post('invitations', this.form, this.getTokenHeader());
						this.$router.push({ name: 'InvitationEdit', params: { id: response.data } }).catch(err => { this.log(err); });
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			}
		}
	}
</script>