<template>
	<div>
		<auth-container v-if="$route.name === 'ConferenceList'" :page="page" require-admin>
			<h2 class="mb-4">Conferences</h2>

			<grid-view api-url="conferences" use-filter
					   :fields="table.fields"
					   :default-sort="table.sort" :default-reverse="table.reverse"
					   collection-description="conferences" show-create
					   item-name="conference"></grid-view>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'ConferenceList',
		components: {
			GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'name', sortable: true },
						{ key: 'year', sortable: true },
						{ key: 'location', sortable: true },
						{ key: 'startDate', label: 'Start', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY') } },
						{ key: 'endDate', label: 'End', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY') } },
						{ key: 'isClosed', label: 'Closed?', sortable: true, formatter: (value) => { return value ? '✓' : '' } }
					],
					sort: 'id',
					reverse: true,
					itemsPerPage: 50
				}
			}
		}
	}
</script>