<template>
	<workshop-registration-form></workshop-registration-form>
</template>

<script>
	import WorkshopRegistrationForm from '@/views/manage/workshops/registrations/WorkshopRegistrationForm';

	export default {
		name: 'WorkshopRegistrationCreate',
		components: {
			WorkshopRegistrationForm
		}
	}
</script>