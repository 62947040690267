<template>
	<auth-container :page="page" require-admin>
		<h2 class="mb-4">Edit invitation</h2>

		<b-alert variant="danger" show class="mb-4">
			<strong>Important:</strong> the online system cannot lock the PDF.
			Be sure to turn on copy protection in Adobe Acrobat before sending to the user.
		</b-alert>

		<error-list :errors="page.saveErrors"></error-list>
		<success-alert ref="savedAlert" text="Changes saved." />

		<b-form @submit.prevent="save">
			<b-form-group label="Invitee's Full Name" :invalid-feedback="requiredFeedback($v.form.name)">
				<b-form-input v-model.trim="$v.form.name.$model" type="text" :state="getValidState($v.form.name)"></b-form-input>
			</b-form-group>

			<b-form-group label="Invitee's Contact Information" :invalid-feedback="requiredFeedback($v.form.contact)">
				<b-form-textarea v-model.trim="$v.form.contact.$model" rows="5" :state="getValidState($v.form.contact)"></b-form-textarea>
			</b-form-group>

			<b-form-group label="Letter Contents" :invalid-feedback="requiredFeedback($v.form.contents)">
				<b-form-textarea v-model.trim="$v.form.contents.$model" rows="10" :state="getValidState($v.form.contents)"></b-form-textarea>
			</b-form-group>

			<fixed-action-bar :cols-lg="10" :offset-lg="2">
				<a :href="`/data/invitation/${id}?access_token=${localStorageToken}`" class="btn btn-info mr-2" target="_blank">
					Get Invitation
					<font-awesome-icon :icon="['fas', 'file-pdf']" fixed-width class="ml-1" />
				</a>

				<save-button :saving="page.saving" class="mr-2" :disabled="!$v.$anyDirty" />
				<back-button class="btn btn-secondary mr-2" />
			</fixed-action-bar>
		</b-form>
	</auth-container>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';

	export default {
		name: 'InvitationEdit',
		data() {
			return {
				id: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: []
				},
				form: {
					conferenceId: null,
					name: null,
					contact: null,
					contents: null
				}
			}
		},
		validations: {
			form: {
				conferenceId: { required },
				name: { required },
				contact: { required },
				contents: { required }
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.id = this.$route.params.id;

				try {
					const response = await this.$http.get(`invitations/${this.id}`, this.getTokenHeader());
					this.log(response.data);
					this.form = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.put(`invitations/${this.id}`, this.form, this.getTokenHeader());
						this.log(response.data);
						this.$refs.savedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			}
		}
	}
</script>