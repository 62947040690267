<template>
	<div>
		<auth-container v-if="$route.name === 'CertificateList'" :page="page" require-admin>
			<h2 class="mb-4">Certificates</h2>

			<grid-view api-url="certificates" delete-api-url="certificates" use-filter
					   :fields="table.fields"
					   :default-sort="table.sort" :default-reverse="table.reverse"
					   collection-description="certificates" show-create
					   item-name="certificate"></grid-view>

			<hr class="my-4" />
			<h3 class="mb-3">Actions</h3>

			<error-list :errors="page.saveErrors"></error-list>
			<success-alert ref="savedAlert" text="Changes saved." />

			<b-form-group>
				<b-form-select v-model="selectedConferenceId" :options="options.conferences" required>
					<template v-slot:first>
						<option :value="null" disabled>-- Select a conference --</option>
					</template>
				</b-form-select>
			</b-form-group>

			<save-button :saving="page.saving" class="mr-2" text="Email All" variant="info" @click.native="email" />
			<save-button :saving="page.saving" class="mr-2" text="Delete All" variant="danger" @click.native="deleteAll" />
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';

	export default {
		name: 'CertificateList',
		components: {
			GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'conference', sortable: true },
						{ key: 'name', sortable: true },
						{ key: 'email', sortable: true },
						{ key: 'delete', label: '' }
					],
					sort: 'name',
					reverse: true,
					itemsPerPage: 50
				},
				options: {
					conferences: []
				},
				selectedConferenceId: null
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.id = this.$route.params.id;

				try {
					const confResponse = await this.$http.get(`invitations/conferences`, this.getTokenHeader());
					this.options.conferences = confResponse.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async email() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.isNullOrEmpty(this.selectedConferenceId)) {
					this.page.saveErrors.push('Please select a conference.');
				} else {
					try {
						const response = await this.$http.post(`conferences/certificatenotices/${this.selectedConferenceId}`, {}, this.getTokenHeader());
						this.log(response.data);
						this.$refs.savedAlert.startAlert();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
						console.log(error);
					}
				}

				this.page.saving = false;
			},
			async deleteAll() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.isNullOrEmpty(this.selectedConferenceId)) {
					this.page.saveErrors.push('Please select a conference.');
				} else {
					try {
						const response = await this.$http.delete(`certificates/deleteall/${this.selectedConferenceId}`, this.getTokenHeader());
						this.log(response.data);
						this.$refs.savedAlert.startAlert();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			}
		}
	}
</script>