<template>
	<registration-form></registration-form>
</template>

<script>
	import RegistrationForm from '@/views/manage/registrations/RegistrationForm';

	export default {
		name: 'RegistrationCreate',
		components: {
			RegistrationForm
		}
	}
</script>