<template>
	<div id="content-frame">
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<error-list v-if="page.errors.length > 0" :errors="page.errors"></error-list>
			<div v-else-if="page.submitted" class="py-5">
				<b-container>
					<div class="jumbotron">
						<h1>Your changes have been saved.</h1>
						<p class="lead">
							Thank you for updating your abstract. Please close this window if you are done, or click a button below for
							further action.
						</p>
						<div>
							<b-button type="button" variant="primary" size="lg" :href="data.conference.website" class="mr-2">Visit the conference website</b-button>
							<b-button type="button" variant="light" size="lg" @click="reload" class="border">Make more changes</b-button>
						</div>
					</div>
				</b-container>
			</div>
			<div v-else-if="!page.verified" class="max-w-400 mx-auto py-5">
				<b-form @submit.prevent="verify" class="form-signin py-3">
					<h1 class="h3 my-3 font-weight-normal">Edit an abstract</h1>

					<error-list :errors="page.saveErrors"></error-list>

					<p>
						Abstract code: <span class="text-info">{{id}}</span>
					</p>

					<label for="email" class="sr-only">Enter the presenter's email address</label>
					<b-form-input type="email" v-model="verifyEmail" placeholder="Email" required autofocus />

					<div class="mt-3">
						<save-button :saving="page.saving" text="Find abstract" />
					</div>
				</b-form>
			</div>
			<div v-else>
				<div v-if="!data.conference.isOpenForEdits" class="py-5">
					<b-container>
						<b-alert variant="warning" show>
							We are preparing the conference program and online changes are no longer allowed.
							Please contact <a :href="`mailto:${contactEmail}`">{{contactEmail}}</a>
							if you have an important, major change that is needed.
						</b-alert>
					</b-container>
				</div>
				<div v-else-if="data.staticDetails.status === 2" class="py-5">
					<b-container>
						<b-alert variant="warning" show>
							Your abstract was rejected and is no longer accessible. 
							You can submit a new abstract <router-link :to="`/submit-abstract/${data.conference.urlModifier}`">here</router-link>.
						</b-alert>
					</b-container>
				</div>
				<div v-else>
					<div class="bg-gradient-light-blue py-5">
						<b-container>
							<h1>{{data.conference.name}}</h1>
							<p class="lead">
								Use the form below to make changes to your abstract.
								Review status: <b-badge :variant="paperStatusVariant" pill>{{paperStatusDescription}}</b-badge>
							</p>
						</b-container>
					</div>
					<div class="py-5">
						<b-container>
							<b-form @submit.prevent="save">
								<error-list :errors="page.saveErrors"></error-list>

								<h2 class="h4">Who will present at the conference?</h2>
								<p>
									Please enter the name and email address of the person who will present the paper at the conference.
									You can change this information later if needed.
								</p>
								<b-row>
									<b-col md>
										<b-form-group label="First name (given name)" :invalid-feedback="requiredFeedback($v.data.paper.presenterFirstName)">
											<b-form-input autofocus v-model.trim="$v.data.paper.presenterFirstName.$model" type="text" :state="getValidState($v.data.paper.presenterFirstName)"></b-form-input>
										</b-form-group>
									</b-col>
									<b-col md>
										<b-form-group label="Last name (family/surname)" :invalid-feedback="requiredFeedback($v.data.paper.presenterLastName)">
											<b-form-input v-model.trim="$v.data.paper.presenterLastName.$model" type="text" :state="getValidState($v.data.paper.presenterLastName)"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col md>
										<b-form-group label="Email address">
											<b-form-input v-model.trim="$v.data.paper.presenterEmail.$model" type="email" :state="getValidState($v.data.paper.presenterEmail)"></b-form-input>
											<b-form-invalid-feedback v-if="!$v.data.paper.presenterEmail.required">Required</b-form-invalid-feedback>
											<b-form-invalid-feedback v-if="!$v.data.paper.presenterEmail.email">Please enter a valid email address</b-form-invalid-feedback>
										</b-form-group>
									</b-col>
									<b-col md>
										<b-form-group label="Affiliation (organization or university)" :invalid-feedback="requiredFeedback($v.data.paper.presenterAffiliation)">
											<b-form-input v-model.trim="$v.data.paper.presenterAffiliation.$model" type="text" :state="getValidState($v.data.paper.presenterAffiliation)"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>

								<hr class="my-4" />

								<h2 class="h4">Authors</h2>

								<p>
									Please add all authors for your paper in the correct <abbr title="Click and drag a row to sort">order</abbr>, including the presenter above if applicable.
									(To remove, just clear the text from the blanks.)
								</p>

								<p>
									CA = Corresponding author; email address is required for the corresponding author, but optional for other authors.
								</p>

								<table class="table table-striped table-sm table-responsive table-valign-m">
									<thead class="thead-light">
										<tr>
											<th></th>
											<th><abbr title="Corresponding author">CA</abbr></th>
											<th>First name (given name)</th>
											<th>Last name (family/surname)</th>
											<th style="width:20rem">Email (required for CA)</th>
											<th style="width:25rem">Affiliation (optional)</th>
										</tr>
									</thead>
									<draggable v-model="data.paper.authors" tag="tbody">
										<tr v-for="(a, i) in data.paper.authors" :key="i">
											<td><font-awesome-icon :icon="['fas', 'sort']" fixed-width /></td>
											<td><b-checkbox v-model="a.isCorresponding"></b-checkbox></td>
											<td><b-form-input v-model="a.firstName" type="text"></b-form-input></td>
											<td><b-form-input v-model="a.lastName" type="text"></b-form-input></td>
											<td><b-form-input v-model="a.email" type="email"></b-form-input></td>
											<td><b-form-input v-model="a.affiliation" type="text"></b-form-input></td>
										</tr>
									</draggable>
								</table>

								<div>
									<b-button type="button" @click="addAuthor" variant="primary">Add an author</b-button>
								</div>

								<hr class="my-4" />

								<h2 class="h4 mb-3">Paper information</h2>

								<p>
									This paper was submitted to the topic: {{data.staticDetails.topic}}
									(this cannot be changed yourself; we will arrange your paper in a session that fits best)
								</p>

								<b-form-group label="Title" :invalid-feedback="requiredFeedback($v.data.paper.title)">
									<b-form-input v-model.trim="$v.data.paper.title.$model" type="text" :state="getValidState($v.data.paper.title)"></b-form-input>
								</b-form-group>

								<b-form-group label="Type or paste your abstract" :invalid-feedback="requiredFeedback($v.data.paper.abstract)">
									<tinymce-editor :api-key="tinymce.api" :init="tinymce.init" v-model.trim="$v.data.paper.abstract.$model" :state="getValidState($v.data.paper.abstract)"></tinymce-editor>
									<b-form-invalid-feedback v-if="!$v.data.paper.abstract.required">Required</b-form-invalid-feedback>
								</b-form-group>

								<b-form-group label="Keywords (optional)" :invalid-feedback="requiredFeedback($v.data.paper.keywords)">
									<b-form-input v-model.trim="$v.data.paper.keywords.$model" type="text" :state="getValidState($v.data.paper.keywords)"></b-form-input>
								</b-form-group>

								<hr class="my-4" />

								<h2 class="h4">Scheduling</h2>

								<div v-if="data.staticDetails.status !== 1">
									<p>
										Oral presentations are organized into parallel sessions spread over 3 days
										({{data.conference.startDate | date('MMMM Do')}} to {{data.conference.endDate | date('MMMM Do')}}).
										Space in the first and second day is limited, and we appreciate if you are open to presenting on the
										last day. Oral presentations should be 15-20 minutes long.
									</p>
									<p>
										Posters are shown in a single dedicated session.
										Recommended poster size will be sent at a later time along with the schedule.
									</p>
									<p>
										<strong>Please select if you would prefer an oral or poster presentation.</strong>
										If oral is selected, the scientific committee may decide the work is better presented as a poster.
									</p>

									<b-form-radio-group v-model="data.paper.isPoster" buttons button-variant="outline-primary" size="lg" class="present-formats">
										<b-form-radio :value="false"><font-awesome-icon v-if="!data.paper.isPoster" :icon="['fas', 'check']" /> Oral</b-form-radio>
										<b-form-radio :value="true"><font-awesome-icon v-if="data.paper.isPoster" :icon="['fas', 'check']" /> Poster</b-form-radio>
									</b-form-radio-group>
								</div>
								<div v-else>
									Your paper was accepted as {{selectedFormat}}.
									This cannot be changed online. Please contact <a :href="`mailto:${contactEmail}`">{{contactEmail}}</a>
									if you need to switch formats.
								</div>

								<p class="mt-4" v-if="data.conference.disableRemotePresentation">
									<strong>This conference will be IN-PERSON ONLY</strong>. Remote presentation is not possible at this conference.
									Please only submit an abstract if you plan to attend in person.
								</p>

								<p class="mt-4" v-else-if="data.paper.isPoster">
									<strong>Posters may only be presented IN-PERSON</strong>.
									Please only submit an abstract for poster presentation if you plan to attend in person.
								</p>

								<p class="mt-4" v-else>
									<strong>Do you plan to attend the conference in person or participate remotely?</strong>
									Remote presentation will be possible the last day of the conference. Please keep in mind the conference location and timezone.
									If you are not sure, please note this in the scheduling restrictions field below.
								</p>

								<b-form-radio-group v-model="data.paper.isRemote" buttons button-variant="outline-primary" size="lg" class="present-formats">
									<b-form-radio :value="false"><font-awesome-icon v-if="!data.paper.isRemote" :icon="['fas', 'check']" /> In-Person</b-form-radio>
									<b-form-radio :value="true"
										v-if="!data.conference.disableRemotePresentation && !data.paper.isPoster"><font-awesome-icon v-if="data.paper.isRemote" :icon="['fas', 'check']" /> Remote</b-form-radio>
								</b-form-radio-group>

								<p class="mt-4">
									<strong>Do you have any scheduling restrictions?</strong>
									Please list any days and times you are unable to present.
									Leave blank if you have no conflicts.
								</p>

								<div>
									<b-form-textarea rows="3" v-model.trim="$v.data.paper.scheduleRequests.$model" :state="getValidState($v.data.paper.scheduleRequests)" placeholder="(e.g., I cannot present Friday)"></b-form-textarea>
								</div>

								<p>
									We cannot guarantee that we can meet every schedule request, but we will do our best.
									The more we know in advance, the better we can accommodate you.
								</p>

								<hr class="my-4" />

								<p v-if="$v.$anyDirty">
									Everything look good? Click the review button below to preview your submission before sending it
									to the committee.
								</p>
								<p v-else class="text-danger">
									Please complete all required fields.
								</p>

								<error-list :errors="page.saveErrors"></error-list>

								<save-button :saving="page.saving" text="Save Changes" size="lg" />
							</b-form>
						</b-container>
					</div>
				</div>
			</div>
		</div>
		<footer-data></footer-data>
	</div>
</template>

<script>
	import { required, email } from 'vuelidate/lib/validators';
	import draggable from 'vuedraggable';
	import Editor from '@tinymce/tinymce-vue';

	export default {
		name: 'EditAbstract',
		components: {
			draggable, 'tinymce-editor': Editor
		},
		data() {
			return {
				id: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					saving: false,
					saveErrors: [],
					verified: false,
					submitted: false
				},
				verifyEmail: null,
				data: {
					paper: {
						originalPresenterEmail: null,
						presenterFirstName: null,
						presenterLastName: null,
						presenterEmail: null,
						presenterAffiliation: null,
						title: null,
						authors: [
							{
								firstName: null,
								lastName: null,
								email: null,
								affiliation: null,
								isCorresponding: false
							}
						],
						abstract: null,
						keywords: null,
						isPoster: false,
						isRemote: false,
						scheduleRequests: null
					},
					topics: [],
					sessions: [],
					conference: {

					},
					staticDetails: {}
				},
				options: {
					formats: [
						{ text: 'Oral', value: false },
						{ text: 'Poster', value: true }
					]
				},
				tinymce: {
					api: 'umc6fcf2lmc4vpzyrqb2jfcr2slzvqb94kk4ac6wl757gx5b',
					init: {
						height: 400,
						menubar: false,
						plugins: [
							'advlist autolink lists link image charmap print preview anchor',
							'searchreplace visualblocks code fullscreen',
							'insertdatetime media table paste code help wordcount'
						],
						toolbar: 'undo redo | formatselect | bold italic underline | link unlink | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code',
					}
				}
			}
		},
		validations: {
			data: {
				paper: {
					originalPresenterEmail: { required, email },
					presenterFirstName: { required },
					presenterLastName: { required },
					presenterEmail: { required, email },
					presenterAffiliation: { required },
					title: { required },
					abstract: { required },
					keywords: {},
					isPoster: {},
					isRemote: {},
					scheduleRequests: {}
				}
			}
		},
		methods: {
			async verify() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					var data = {
						accessCode: this.id,
						email: this.verifyEmail
					}
					const response = await this.$http.post(`edit-abstract/verify`, data);
					this.log(response.data);
					this.data = response.data;
					this.page.verified = true;
				} catch (error) {
					this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			async save() {
				this.log(this.data.paper);
				this.page.saveErrors = [];
				this.page.saving = true;

				var authorErrors = this.checkAuthors();
				if (this.data.conference.disableRemotePresentation || this.data.paper.isPoster) {
					this.data.paper.isRemote = false;
				}

				if (authorErrors.length > 0) {
					this.page.saveErrors = authorErrors;
				} else if (this.$v.$invalid) {
					this.page.saveErrors.push('Please scroll through the form and fix any errors then try again.');
				} else {
					this.log(this.data.paper);
					try {
						const response = await this.$http.put(`edit-abstract/${this.id}`, this.data.paper);
						this.log(response.data);
						this.page.submitted = true;
					} catch (error) {
						this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			addAuthor() {
				this.data.paper.authors.push({
					firstName: null,
					lastName: null,
					email: null,
					affiliation: null,
					isCorresponding: false
				});
			},
			checkAuthors() {
				var errors = [];

				for (var i = 0; i < this.data.paper.authors.length; i++) {
					var a = this.data.paper.authors[i];
					if (this.isNullOrEmpty(a.firstName) && this.isNullOrEmpty(a.lastName)) {
						this.data.paper.authors.splice(i, 1);
						this.log(i);
					} else if (a.isCorresponding && this.isNullOrEmpty(a.email)) {
						errors.push('Please provide an email address for the corresponding author.')
					}
				}

				if (this.data.paper.authors.length < 1) {
					errors.push('Please enter at least one author for the paper.');
				}

				return errors;
			},
			reload() {
				window.location.reload(true);
			}
		},
		computed: {
			paperStatusDescription() {
				var status = this.data.staticDetails.status;
				var keys = Object.keys(this.paperStatus);
				this.log(status);
				this.log(keys);
				for (var i = 0; i < keys.length; i++) {
					if (this.paperStatus[keys[i]].value === status) return this.paperStatus[keys[i]].text;
				}
				return '';
			},
			paperStatusVariant() {
				var status = this.data.staticDetails.status;
				var keys = Object.keys(this.paperStatus);
				for (var i = 0; i < keys.length; i++) {
					if (this.paperStatus[keys[i]].value === status) return this.paperStatus[keys[i]].variant;
				}
				return '';
			},
			selectedFormat() {
				return this.data.paper.isPoster ? 'a poster' : 'an oral presentation';
			},
			authorHtml() {
				var html = '<p class="authors">';
				var orderHtml = '<ol>';

				var cnt = this.data.paper.authors.length;
				var affiliationIndex = 1;
				for (var i = 0; i < cnt; i++) {
					var a = this.data.paper.authors[i];
					var comma = i === cnt - 1 ? '' : ', ';
					var corresponding = a.isCorresponding ? '*' : '';
					var affiliation = '';
					if (!this.isNullOrEmpty(a.affiliation) || a.isCorresponding) {
						affiliation = affiliationIndex;
						affiliationIndex++;

						var correspondingNote = a.isCorresponding ? `Email: ${a.email} (corresponding author)` : '';
						orderHtml += `<li>${a.affiliation} ${correspondingNote}</li >`;
					}

					html += `${a.firstName} ${a.lastName}<sup>${corresponding}${affiliation}</sup>${comma}`;
				}
				html += '</p>';
				orderHtml += '</ol>';

				return html + orderHtml;
			}
		}
	}
</script>
