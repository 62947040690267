<template>
	<div>
		<div style="height:100px"></div>
		<div class="fixed-bottom">
			<b-container fluid class="p-0">
				<b-row no-gutters>
					<b-col :md="cols" :offset-md="offset" :lg="colsLg" :offset-lg="offsetLg" class="bg-light border-top">
						<div class="py-3 d-flex px-3 px-md-4 align-items-center">
							<slot></slot>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FixedActionBar',
	props: {
		cols: {
			type: Number,
			default: 9
		},
		offset: {
			type: Number,
			default: 3
		},
		colsLg: {
			type: Number,
			default: 9
		},
		offsetLg: {
			type: Number,
			default: 3
		}
	}
}
</script>
