<template>
	<div>
		<auth-container v-if="$route.name === 'TopicList'" :page="page" require-admin>
			<h2 class="mb-4">Topics</h2>

			<grid-view api-url="topics" delete-api-url="topics" use-filter
					   :fields="table.fields"
					   :default-sort="table.sort" :default-reverse="table.reverse"
					   collection-description="topics" show-create
					   item-name="topic"></grid-view>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';

	export default {
		name: 'TopicList',
		components: {
			GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'name', sortable: true },
						{ key: 'shortName', sortable: true },
						{ key: 'numPapers', label: '# Papers', sortable: true },
						{ key: 'inAllByDefault', label: 'Default?', sortable: true, formatter: (value) => { return value ? '✓' : '' } },
						{ key: 'delete', label: '' }
					],
					sort: 'name',
					reverse: false,
					itemsPerPage: 50
				}
			}
		}
	}
</script>