<template>
	<div>
		<auth-container v-if="$route.name === 'PaperList'" :page="page">
			<h2 class="mb-3">{{conference.name}}</h2>

			<p class="mb-4">
				<a :href="`/data/abstracts/${confId}?access_token=${localStorageToken}`" class="btn btn-info mr-2">
					Download Abstracts
					<font-awesome-icon :icon="['fas', 'file-excel']" fixed-width class="ml-1" />
				</a>

				<router-link :to="`/book-of-abstracts/${confId}`" class="btn btn-info" target="_blank">
					Book of Abstracts
					<font-awesome-icon :icon="['fas', 'external-link-alt']" fixed-width class="ml-1" />
				</router-link>
			</p>

			<b-tabs v-model="tabIndex" content-class="mt-4" class="page-nav-tabs">
				<b-tab :title="`Pending Review (${conference.totals.pending})`">
					<div v-if="!isAuthorized(roleNames.admin)" class="mb-4">
						You are assigned to the following topics:
						<div>
							<b-badge pill variant="info" class="mr-1" v-for="(t, i) in conference.topics" :key="i">{{t}}</b-badge>
						</div>
						<div class="mt-3">
							You can view all pending abstracts below, but will only be able to 
							review those in the topics to which you are assigned.
							If an abstract from your email notifications doesn't appear below,
							someone else in the committee already reviewed it.
						</div>
					</div>

					<grid-view :api-url="`papers/${confId}/${paperStatus.pending.value}`" use-filter
							   :fields="table.fields" :row-class-prop="isAuthorized(roleNames.admin) ? '' : 'isMyTopic'"
							   :default-sort="table.sort" :default-reverse="table.reverse"
							   collection-description="pending abstracts"
							   item-name="abstract"></grid-view>
				</b-tab>
				<b-tab :title="`Approved (${conference.totals.approved})`">
					<grid-view :api-url="`papers/${confId}/${paperStatus.approved.value}`" use-filter
							   :fields="table.fields"
							   :default-sort="table.sort" :default-reverse="table.reverse"
							   collection-description="approved abstracts"
							   item-name="abstract"></grid-view>
				</b-tab>
				<b-tab :title="`Sent Back (${conference.totals.sentBackForEdits})`">
					<grid-view :api-url="`papers/${confId}/${paperStatus.sentBackForEdits.value}`" use-filter
							   :fields="table.fields"
							   :default-sort="table.sort" :default-reverse="table.reverse"
							   collection-description="abstracts sent back to the user for changes"
							   item-name="abstract"></grid-view>
				</b-tab>
				<b-tab :title="`Pending Rejection (${conference.totals.pendingRejected})`">
					<grid-view :api-url="`papers/${confId}/${paperStatus.pendingRejected.value}`" use-filter
							   :fields="table.fields"
							   :default-sort="table.sort" :default-reverse="table.reverse"
							   collection-description="abstracts pending rejection"
							   item-name="abstract"></grid-view>
				</b-tab>
				<b-tab :title="`Rejected (${conference.totals.rejected})`">
					<grid-view :api-url="`papers/${confId}/${paperStatus.rejected.value}`" use-filter
							   :fields="table.fields"
							   :default-sort="table.sort" :default-reverse="table.reverse"
							   collection-description="rejected abstracts"
							   item-name="abstract"></grid-view>
				</b-tab>
				<b-tab :title="`Cancelled (${conference.totals.cancelled})`">
					<grid-view :api-url="`papers/${confId}/${paperStatus.cancelled.value}`" use-filter
							   :fields="table.fields"
							   :default-sort="table.sort" :default-reverse="table.reverse"
							   collection-description="cancelled abstracts"
							   item-name="abstract"></grid-view>
				</b-tab>
			</b-tabs>
			
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'PaperList',
		components: {
			GridView
		},
		data() {
			return {
				confId: this.$route.params.conf,
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				conference: {
					name: null,
					totals: {},
					topics: []
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'dateSubmitted', label: 'Submitted', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY h:mm a') } },
						{ key: 'topic', sortable: true },
						{ key: 'presenterFirstName', label: 'First Name', sortable: true },
						{ key: 'presenterLastName', label: 'Last Name', sortable: true },
						{ key: 'title', sortable: true },
						{ key: 'isPoster', label: 'Poster', sortable: true, formatter: (value) => { return value ? '✓' : '' } },
						{ key: 'isRemote', label: 'Remote', sortable: true, formatter: (value) => { return value ? '✓' : '' } },
						{ key: 'isRegistered', label: 'Registered', sortable: true, formatter: (value) => { return value ? '✓' : '' } }
					],
					sort: 'dateSubmitted',
					reverse: true,
					itemsPerPage: 50
				},
				tabIndex: 0
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.confId = this.$route.params.conf;

				try {
					const response = await this.$http.get(`papers/stats/${this.confId}`, this.getTokenHeader());
					this.log(response.data);
					this.conference = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		}
	}
</script>