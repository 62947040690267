<template>
	<div>
		<auth-container :page="page">
			<h2 class="mb-4">Change Your Password</h2>

			<b-alert variant="info" show>
				By default, the system will manage your scientific committee account password and sent it to you
				when new abstracts are submitted to your topics for review.
				By changing your own password below, we'll no longer manage your password and it will not be emailed to you.
				You will be responsible for maintaining your own password from now on.
			</b-alert>

			<error-list :errors="page.password.errors"></error-list>
			<success-alert ref="passwordSavedAlert" text="Changes saved." />

			<b-form @submit.prevent="savePassword">
				<b-form-group label="Current password">
					<b-form-input v-model="password.old" type="password" :state="getValidState($v.password.old)"></b-form-input>
					<b-form-invalid-feedback v-if="!$v.password.old.required">Required</b-form-invalid-feedback>
				</b-form-group>

				<b-form-group label="New password" description="Minimum 10 characters with a number and special character">
					<b-form-input v-model="password.new" type="password" :state="getValidState($v.password.new)"></b-form-input>
					<b-form-invalid-feedback v-if="!$v.password.new.required">Required</b-form-invalid-feedback>
					<b-form-invalid-feedback v-if="!$v.password.new.strongPassword">Must be at least 10 characters with a number and special character</b-form-invalid-feedback>
				</b-form-group>

				<b-form-group label="Confirm new password">
					<b-form-input v-model="password.confirm" type="password" :state="getValidState($v.password.confirm)"></b-form-input>
					<b-form-invalid-feedback v-if="!$v.password.confirm.required">Required</b-form-invalid-feedback>
					<b-form-invalid-feedback v-if="!$v.password.confirm.sameAsPassword">Passwords must match</b-form-invalid-feedback>
				</b-form-group>

				<div class="mt-4">
					<save-button :saving="page.password.saving" />
				</div>
			</b-form>

			<b-alert variant="secondary" show class="mt-5">
				<strong>Need to update your email or personal information?</strong>
				Because your information as a member of the scientific committee is exported for use in other 
				conference program materials, you cannot change this online yourself. 
				Please email <a :href="`mailto:${contactEmail}`">{{contactEmail}}</a> to have it updated.
			</b-alert>

		</auth-container>
	</div>
</template>

<script>
	import { requiredIf, sameAs } from 'vuelidate/lib/validators';

	export default {
		name: 'AccountChangePassword',
		data() {
			return {
				confId: this.$route.params.conf,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					password: {
						errors: [],
						saving: false
					}
				},
				password: {
					old: '',
					new: '',
					confirm: ''
				}
			}
		},
		validations: {
			password: {
				old: { required: requiredIf(function(){ return this.tabIndex == 2; }) },
				new: {
					strongPassword(password) {
						return (
							this.tabIndex != 2 || (
							/[a-z]/.test(password) && // checks for a-z
							/[0-9]/.test(password) && // checks for 0-9
							/\W|_/.test(password) && // checks for special char
							password.length >= 10)
						);
					},
					required: requiredIf(function(){ return this.tabIndex == 2; })
				},
				confirm: {
					sameAsPassword: sameAs('new'),
					required: requiredIf(function(){ return this.tabIndex == 2; })
				}
			}
		},
		methods: {
			async savePassword() {
				this.page.password.errors = [];
				this.page.password.saving = true;

				this.$v.$touch();
				if (this.$v.$invalid) {
					this.page.password.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						var data = {
							oldPassword: this.password.old,
							newPassword: this.password.new
						};
						const response = await this.$http.post('account/changepassword', data, this.getTokenHeader());
						this.log(response.data);
						this.$refs.passwordSavedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.password.errors = this.logError(error);
					}
				}

				this.page.password.saving = false;
			}
		}
	}
</script>