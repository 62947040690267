<template>
	<auth-container :page="page" require-admin>
		<h2 class="mb-4">{{isUpdate ? 'Edit' : 'Add a new'}} topic</h2>
		<error-list :errors="page.saveErrors"></error-list>
		<success-alert ref="savedAlert" text="Changes saved." />

		<b-form @submit.prevent="save">
			<b-form-group label="Name" :invalid-feedback="requiredFeedback($v.form.name)">
				<b-form-input v-model.trim="$v.form.name.$model" type="text" :state="getValidState($v.form.name)"></b-form-input>
			</b-form-group>

			<b-form-group label="Short name" :invalid-feedback="requiredFeedback($v.form.shortName)">
				<b-form-input v-model.trim="$v.form.shortName.$model" type="text" :state="getValidState($v.form.shortName)"></b-form-input>
			</b-form-group>

			<b-form-group>
				<b-checkbox v-model.trim="$v.form.inAllByDefault.$model">Checked by default for inclusion in all conferences?</b-checkbox>
			</b-form-group>

			<fixed-action-bar :cols-lg="10" :offset-lg="2">
				<save-button :saving="page.saving" class="mr-2" :disabled="!$v.$anyDirty" />
				<back-button class="btn btn-secondary mr-2" />
				<b-button v-if="isUpdate" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete this topic?
				This action is permanent and cannot be undone.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</auth-container>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';

	export default {
		name: 'TopicForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			id: {
				type: String,
				default: null
			}
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: [],
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				form: {
					name: null,
					shortName: null,
					inAllByDefault: false
				}
			}
		},
		validations: {
			form: {
				name: { required },
				shortName: { required },
				inAllByDefault: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				if (this.isUpdate) {
					this.page.errors = [];
					this.page.loading = true;

					try {
						const response = await this.$http.get(`topics/${this.id}`, this.getTokenHeader());
						this.log(response.data);
						this.form = response.data;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}

					this.page.loading = false;
				}
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						if (!this.isUpdate) {
							await this.$http.post('topics', this.form, this.getTokenHeader());
							this.$router.push({ name: 'TopicList' }).catch(err => { this.log(err); });
						} else {
							await this.$http.put(`topics/${this.id}`, this.form, this.getTokenHeader());
							this.$refs.savedAlert.startAlert();
							this.$v.$reset();
						}
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`topics/${this.id}`, this.getTokenHeader());
					this.log(response.data);
					this.$router.push({ name: 'TopicList' }).catch(err => { this.log(err); });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		}
	}
</script>