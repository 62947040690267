<template>
	<auth-container :page="page" require-admin>
		<h2 class="mb-4">Edit workshop</h2>

		<b-tabs v-model="tabIndex" content-class="mt-4" class="page-nav-tabs">
			<b-tab title="Settings">
				<error-list :errors="page.form.errors"></error-list>
				<success-alert ref="formSavedAlert" text="Changes saved." />

				<b-form>
					<b-form-group label="Workshop Name" :invalid-feedback="requiredFeedback($v.form.name)">
						<b-form-input v-model.trim="$v.form.name.$model" type="text" :state="getValidState($v.form.name)"></b-form-input>
					</b-form-group>

					<b-row>
						<b-col md>
							<b-form-group label="Start Date" :invalid-feedback="requiredFeedback($v.form.startDate)">
								<b-form-input v-model.trim="$v.form.startDate.$model" type="date" :state="getValidState($v.form.startDate)"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="End Date" :invalid-feedback="requiredFeedback($v.form.endDate)">
								<b-form-input v-model.trim="$v.form.endDate.$model" type="date" :state="getValidState($v.form.endDate)"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>

					<b-form-group label="Website" :invalid-feedback="requiredFeedback($v.form.website)">
						<b-form-input v-model.trim="$v.form.website.$model" type="text" :state="getValidState($v.form.website)"></b-form-input>
					</b-form-group>

					<b-form-group label="Flywire Product Name" :invalid-feedback="requiredFeedback($v.form.productName)">
						<b-form-input v-model.trim="$v.form.productName.$model" type="text" :state="getValidState($v.form.productName)"></b-form-input>
					</b-form-group>

					<hr class="my-4" />

					<b-form-group label="Instructor Name" :invalid-feedback="requiredFeedback($v.form.instructorName)">
						<b-form-textarea v-model.trim="$v.form.instructorName.$model" rows="3" :state="getValidState($v.form.instructorName)"></b-form-textarea>
					</b-form-group>

					<b-form-group label="Instructor Affiliation" :invalid-feedback="requiredFeedback($v.form.instructorAffiliation)">
						<b-form-input v-model.trim="$v.form.instructorAffiliation.$model" type="text" :state="getValidState($v.form.instructorAffiliation)"></b-form-input>
					</b-form-group>

					<hr class="my-4" />

					<b-form-group label="Certificate Title" :invalid-feedback="requiredFeedback($v.form.certificateTitle)">
						<b-form-input v-model.trim="$v.form.certificateTitle.$model" type="text" :state="getValidState($v.form.certificateTitle)"></b-form-input>
					</b-form-group>

					<b-form-group label="Certificate Hours" :invalid-feedback="requiredFeedback($v.form.certificateHours)">
						<b-form-input v-model.trim="$v.form.certificateHours.$model" type="text" :state="getValidState($v.form.certificateHours)"></b-form-input>
					</b-form-group>

					<b-form-group label="Certificate Local Logo Position Style" :invalid-feedback="requiredFeedback($v.form.certificatePositionStyle)">
						<b-form-input v-model.trim="$v.form.certificatePositionStyle.$model" type="text" :state="getValidState($v.form.certificatePositionStyle)"></b-form-input>
					</b-form-group>

					<b-form-group label="Certificate Local Logo Size Style" :invalid-feedback="requiredFeedback($v.form.certificateSizeStyle)">
						<b-form-input v-model.trim="$v.form.certificateSizeStyle.$model" type="text" :state="getValidState($v.form.certificateSizeStyle)"></b-form-input>
					</b-form-group>
				</b-form>
			</b-tab>
			<b-tab title="Files">
				<error-list :errors="page.files.errors"></error-list>

				<p>
					Upload certificate signature ({{signatureDropzoneOptions.acceptedFiles}})
				</p>
				<vue-dropzone id="sigFileUpload" :options="signatureDropzoneOptions" @vdropzone-success="signatureFileUploaded" @vdropzone-error="uploadError"></vue-dropzone>
				<div v-if="!isNullOrEmpty(data.signature)">
					<img :src="data.signature" alt="Signature" style="max-width:200px" />
				</div>

				<p>
					Upload optional certificate logo ({{logoDropzoneOptions.acceptedFiles}})
				</p>
				<vue-dropzone id="logoFileUpload" :options="logoDropzoneOptions" @vdropzone-success="logoFileUploaded" @vdropzone-error="uploadError"></vue-dropzone>
				<div v-if="!isNullOrEmpty(data.logo)">
					<img :src="data.logo" alt="Logo" style="max-width:200px" />
				</div>
			</b-tab>
			<b-tab title="Send Email">
				<error-list :errors="page.emails.errors"></error-list>
				<success-alert ref="emailsSavedAlert" text="Emails sent." />

				<b-form>
					<b-form-group label="Subject" :invalid-feedback="requiredFeedback($v.emailForm.subject)">
						<b-form-input v-model.trim="$v.emailForm.subject.$model" type="text" :state="getValidState($v.emailForm.subject)"></b-form-input>
					</b-form-group>

					<b-form-group label="Message" :invalid-feedback="requiredFeedback($v.emailForm.body)">
						<b-form-textarea v-model.trim="$v.emailForm.body.$model" rows="6" :state="getValidState($v.emailForm.body)"></b-form-textarea>
					</b-form-group>

					<b-form-group label="BCC" :invalid-feedback="requiredFeedback($v.emailForm.bcc)">
						<b-form-input v-model.trim="$v.emailForm.bcc.$model" type="text" :state="getValidState($v.emailForm.bcc)"></b-form-input>
					</b-form-group>
				</b-form>
			</b-tab>
		</b-tabs>

		<fixed-action-bar :cols-lg="10" :offset-lg="2">
			<save-button v-if="tabIndex == 0" :saving="page.form.saving" @click.native="save" class="mr-2" :disabled="!$v.form.$anyDirty" />
			<save-button v-if="tabIndex == 2" :saving="page.emails.saving" @click.native="sendEmailForm" class="mr-2" text="Send Email" />
			<b-button v-if="tabIndex == 0" variant="info" :to="`/manage/workshops/registrations/${form.id}`" class="mr-2">Go to Registrations</b-button>
			<back-button class="btn btn-secondary mr-2" />
			<b-button v-if="data.numRegistered === 0" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
		</fixed-action-bar>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete this workshop?
				This action is permanent and cannot be undone.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</auth-container>
</template>

<script>
	import { required, integer } from 'vuelidate/lib/validators';
	import moment from 'moment';
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';

	export default {
		name: 'WorkshopEdit',
		components: {
			vueDropzone: vue2Dropzone
		},
		data() {
			return {
				id: this.$route.params.id,
				signatureDropzoneOptions: {
					url: `/api/workshops/uploadsignature/${this.$route.params.id}`,
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.jpg, .jpeg, .png, .svg, .gif'
				},
				logoDropzoneOptions: {
					url: `/api/workshops/uploadlogo/${this.$route.params.id}`,
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.jpg, .jpeg, .png, .svg, .gif'
				},
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					form: {
						errors: [],
						saving: false
					},
					delete: {
						errors: [],
						saving: false,
						show: false
					},
					import: {
						errors: [],
						saving: false,
						show: false
					},
					emails: {
						errors: [],
						saving: false
					},
					files: {
						errors: [],
						saving: false
					}
				},
				tabIndex: 0,
				form: {
					id: null,
					name: null,
					website: null,
					startDate: null,
					endDate: null,
					instructorAffiliation: null,
					instructorName: null,
					certificatePositionStyle: null,
					certificateSizeStyle: null,
					productName: null,
					certificateTitle: null,
					certificateHours: null
				},
				data: {
					signature: null,
					logo: null,
					numRegistered: 0
				},
				emailForm: {
					subject: null,
					body: null,
					bcc: null
				},
				tinymce: {
					api: 'umc6fcf2lmc4vpzyrqb2jfcr2slzvqb94kk4ac6wl757gx5b',
					init: {
						height: 400,
						menubar: false,
						plugins: [
							'advlist autolink lists link image charmap print preview anchor',
							'searchreplace visualblocks code fullscreen',
							'insertdatetime media table paste code help wordcount'
						],
						toolbar: 'undo redo | formatselect | bold italic underline | link unlink | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code',
					}
				}
			}
		},
		validations: {
			form: {
				id: { required },
				name: { required },
				website: { required },
				startDate: { required },
				endDate: { required },
				instructorName: {},
				instructorAffiliation: {},
				certificatePositionStyle: {},
				certificateSizeStyle: {},
				productName: { required },
				certificateTitle: {},
				certificateHours: {}
			},
			emailForm: {
				subject: { required },
				body: { required },
				bcc: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.id = this.$route.params.id;

				try {
					const response = await this.$http.get(`workshops/${this.id}`, this.getTokenHeader());
					this.log(response.data);
					this.form = response.data.workshop;
					this.data = response.data.data;

					this.form.startDate = moment(this.form.startDate).format('YYYY-MM-DD');
					this.form.endDate = moment(this.form.endDate).format('YYYY-MM-DD');
					this.emailForm.subject = this.form.name;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.form.errors = [];
				this.page.form.saving = true;

				if (this.$v.form.$invalid) {
					this.page.form.errors.push('Please fix the errors below and try again.');
					this.log(this.$v);
				} else {
					try {
						const response = await this.$http.put(`workshops/${this.id}`, this.form, this.getTokenHeader());
						this.log(response.data);
						this.$refs.formSavedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.form.errors = this.logError(error);
					}
				}

				this.page.form.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`workshops/${this.id}`, this.getTokenHeader());
					this.log(response.data);
					this.$router.push({ name: 'WorkshopList' }).catch(err => { this.log(err); });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			async signatureFileUploaded(file, response) {
				this.log(file);
				this.log(response);
				this.page.files.errors = [];
				await this.get();
			},
			async logoFileUploaded(file, response) {
				this.log(file);
				this.log(response);
				this.page.files.errors = [];
				await this.get();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.files.errors.push(message);
			},
			async sendEmailForm() {
				this.page.emails.errors = [];
				this.page.emails.saving = true;

				try {
					const response = await this.$http.post(`workshops/sendemail/${this.id}`, this.emailForm, this.getTokenHeader());
					this.log(response.data);
					this.$refs.emailsSavedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.emails.errors = this.logError(error);
				}

				this.page.emails.saving = false;
			}
		}
	}
</script>