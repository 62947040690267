<template>
	<div id="content-frame">
		<div id="main-panel">
			<page-loading :loading="page.loading"></page-loading>
			<div v-if="!page.loading">
				<b-container class="pt-4">
					<div class="bg-primary text-white p-2 text-center">
						{{paper.conference}} SWAT Conference: {{paper.topic}}
						<div>Status: {{paperStatusDescription}}</div>
					</div>

					<h1 class="h2 mt-4">{{paper.title}}</h1>
					<div v-html="authorHtml"></div>

					<h2 class="text-uppercase h4 mt-4">Abstract</h2>
					<div v-html="paper.abstract"></div>

					<h2 class="text-uppercase h4 mt-4">Keywords</h2>
					<p>{{paper.keywords}}</p>

					<div class="d-print-none">
						<back-button class="btn btn-secondary mr-2" />
					</div>
				</b-container>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PaperPrint',
		data() {
			return {
				id: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					delete: {
						errors: [],
						saving: false,
						show: false
					}
				},
				paper: {
					authors: []
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.id = this.$route.params.id;

				try {
					const response = await this.$http.get(`papers/public/${this.id}`, this.getTokenHeader());
					this.log(response.data);
					this.paper = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		},
		computed: {
			paperStatusDescription() {
				var status = this.paper.status;
				var keys = Object.keys(this.paperStatus);
				for (var i = 0; i < keys.length; i++) {
					if (this.paperStatus[keys[i]].value === status) return this.paperStatus[keys[i]].text;
				}
				return '';
			},
			paperStatusVariant() {
				var status = this.paper.status;
				var keys = Object.keys(this.paperStatus);
				for (var i = 0; i < keys.length; i++) {
					if (this.paperStatus[keys[i]].value === status) return this.paperStatus[keys[i]].variant;
				}
				return '';
			},
			paperFormat() {
				return this.paper.isPoster ? 'Poster' : 'Oral presentation';
			},
			authorHtml() {
				var html = '<p class="authors mb-1">';
				var orderHtml = '<ol class="mb-1">';

				var cnt = this.paper.authors.length;
				var affiliationIndex = 1;
				for (var i = 0; i < cnt; i++) {
					var a = this.paper.authors[i];
					var comma = i === cnt - 1 ? '' : ', ';
					var corresponding = a.isCorresponding ? '*' : '';
					var affiliation = '';
					if (!this.isNullOrEmpty(a.affiliation) || a.isCorresponding) {
						affiliation = affiliationIndex;
						affiliationIndex++;

						var correspondingNote = a.isCorresponding ? `Email: ${a.email} (corresponding author)` : '';
						orderHtml += `<li>${a.affiliation} ${correspondingNote}</li >`;
					}

					html += `${a.firstName} ${a.lastName}<sup>${corresponding}${affiliation}</sup>${comma}`;
				}
				html += '</p>';
				orderHtml += '</ol>';

				return html + orderHtml;
			}
		}
	}
</script>