<template>
	<div id="content-frame">
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<error-list v-if="page.errors.length > 0" :errors="page.errors"></error-list>
			<b-container v-else class="py-5">
				<h2>{{data.conference}}</h2>
				<p>
					Please review your scheduled presentation time below carefully.
					Your session topic may have changed from your abstract submission in order to best fit your presentation in the schedule.
					<span v-if="!isConfirmed">If everything looks good, <b>click the button below</b> to confirm your time slot.</span>
					Report any errors or conflicts to <a :href="`mailto:${contactEmail}`">{{contactEmail}}</a> as soon as possible.
				</p>

				<error-list :errors="page.saveErrors"></error-list>

				<b-alert variant="success" :show="isConfirmed">
					<font-awesome-icon :icon="['fas', 'check']" class="mr-2" />
					This presentation schedule is confirmed.
				</b-alert>

				<p v-if="!isConfirmed">
					<save-button :saving="page.saving" @click.native="confirm" text="Confirm Schedule" size="lg" />
				</p>

				<b-table-simple class="border-bottom">
					<b-tbody>
						<b-tr>
							<b-th>Paper Title</b-th>
							<b-td>{{data.title}}</b-td>
						</b-tr>
						<b-tr>
							<b-th>Presenter</b-th>
							<b-td>{{data.presenterFirstName}} {{data.presenterLastName}}</b-td>
						</b-tr>
						<b-tr>
							<b-th>Format</b-th>
							<b-td v-if="data.isPoster">Poster (no template requirements; size should be no larger than A0)</b-td>
							<b-td v-else-if="data.isRemote">Oral, remote</b-td>
							<b-td v-else>Oral, in person</b-td>
						</b-tr>
						<b-tr>
							<b-th>Session</b-th>
							<b-td v-if="isNullOrEmpty(data.session)">NOT SCHEDULED - please contact us if you are expecting to present</b-td>
							<b-td v-else>{{data.session}} - {{data.isPoster ? 'Poster' : data.scheduledTopic}}</b-td>
						</b-tr>
						<b-tr v-if="!isNullOrEmpty(data.session)">
							<b-th>Session Time</b-th>
							<b-td>
								{{data.sessionStart | date('dddd, D MMMM, HH:mm')}} - {{data.sessionEnd | date('HH:mm')}}
								<div v-if="!isNullOrEmpty(data.timezoneLink)"><a :href="data.timezoneLink" target="_blank">{{data.timezoneDescription}}</a></div>
								<div v-if="data.isRemote" class="text-danger">Time above is for {{data.conferenceLocation}}. Please use the link above to compare the difference with your local time zone.</div>
							</b-td>
						</b-tr>
						<b-tr v-if="!isNullOrEmpty(data.session) && !data.isPoster">
							<b-th>Presentation Order</b-th>
							<b-td>
								You will be presenter number <b>{{data.orderInSession}}</b> in the session.
								Each presenter has about <b>20 minutes</b>.
								Please plan to check into your session at least 10 minutes early so we can load your presentation.
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

				<div v-if="!data.isPoster" class="mt-4">
					<b-table-simple bordered style="font-size: 0.9rem" small>
						<b-thead>
							<b-tr class="bg-light">
								<b-th colspan="3" style="font-size: 1rem">All presentations in your session</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr v-for="(p, i) in data.sessionPapers" :key="i">
								<b-td class="nowrap">{{p.time}}</b-td>
								<b-td class="nowrap">{{p.name}}</b-td>
								<b-td>{{p.title}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</div>

				<p v-if="data.isRemote">
					More information about connecting to your remote session will be given at a later date. 
				</p>

				<p v-if="!isConfirmed">
					<save-button :saving="page.saving" @click.native="confirm" text="Confirm Schedule" size="lg" />
				</p>

				<p>
					Don't forget to check the <a :href="data.conferenceWebsite" target="_blank">conference website</a> for the full agenda, hotel recommendations, and travel tips.
				</p>
			</b-container>
		</div>
		<footer-data></footer-data>
	</div>
</template>

<script>
	export default {
		name: 'ConfirmSchedule',
		data() {
			return {
				id: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					saving: false,
					saveErrors: [],
					confirmed: false
				},
				data: {
					isScheduleConfirmed: false
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.id = this.$route.params.id;

				try {
					const response = await this.$http.get(`edit-abstract/get-schedule/${this.id}`);
					this.log(response.data);
					this.data = response.data;

				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async confirm() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					const response = await this.$http.post(`edit-abstract/confirm-schedule/${this.id}`, {});
					this.log(response.data);
					this.page.confirmed = true;
				} catch (error) {
					this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			}
		},
		computed: {
			isConfirmed() {
				return this.page.confirmed || this.data.isScheduleConfirmed;
			}
		}
	}
</script>
