import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home.vue';
import SubmitAbstract from '../views/SubmitAbstract.vue';
import EditAbstract from '../views/EditAbstract.vue';
import ConfirmSchedule from '../views/ConfirmSchedule.vue';
import BookOfAbstracts from '../views/BookOfAbstracts.vue';
import GetCertificates from '../views/GetCertificates.vue';
import ManageDashboard from '../views/manage/ManageDashboard.vue';

import UserList from '../views/manage/users/UserList.vue';
import UserCreate from '../views/manage/users/UserCreate.vue';
import UserEdit from '../views/manage/users/UserEdit.vue';

import ConferenceList from '../views/manage/conferences/ConferenceList.vue';
import ConferenceCreate from '../views/manage/conferences/ConferenceCreate.vue';
import ConferenceEdit from '../views/manage/conferences/ConferenceEdit.vue';

import TopicList from '../views/manage/topics/TopicList.vue';
import TopicCreate from '../views/manage/topics/TopicCreate.vue';
import TopicEdit from '../views/manage/topics/TopicEdit.vue';

import PaperList from '../views/manage/papers/PaperList.vue';
import PaperView from '../views/manage/papers/PaperView.vue';
import PaperEdit from '../views/manage/papers/PaperEdit.vue';
import PaperPrint from '../views/manage/papers/PaperPrint.vue';

import InvitationList from '../views/manage/invitations/InvitationList.vue';
import InvitationCreate from '../views/manage/invitations/InvitationCreate.vue';
import InvitationEdit from '../views/manage/invitations/InvitationEdit.vue';

import CertificateList from '../views/manage/certificates/CertificateList.vue';
import CertificateCreate from '../views/manage/certificates/CertificateCreate.vue';
import CertificateEdit from '../views/manage/certificates/CertificateEdit.vue';

import AccountReset from '../views/AccountReset.vue';
import AccountChangePassword from '../views/manage/account/AccountChangePassword.vue';

import RegparserList from '../views/manage/regparsers/RegparserList.vue';
import RegparserCreate from '../views/manage/regparsers/RegparserCreate.vue';
import RegparserEdit from '../views/manage/regparsers/RegparserEdit.vue';

import RegistrationList from '../views/manage/registrations/RegistrationList.vue';
import RegistrationCreate from '../views/manage/registrations/RegistrationCreate.vue';
import RegistrationEdit from '../views/manage/registrations/RegistrationEdit.vue';

import WorkshopList from '../views/manage/workshops/WorkshopList.vue';
import WorkshopCreate from '../views/manage/workshops/WorkshopCreate.vue';
import WorkshopEdit from '../views/manage/workshops/WorkshopEdit.vue';

import WorkshopRegistrationList from '../views/manage/workshops/registrations/WorkshopRegistrationList.vue';
import WorkshopRegistrationCreate from '../views/manage/workshops/registrations/WorkshopRegistrationCreate.vue';
import WorkshopRegistrationEdit from '../views/manage/workshops/registrations/WorkshopRegistrationEdit.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/', name: 'Home', component: Home
    },
    {
        path: '/submit-abstract/:id', name: 'SubmitAbstract', component: SubmitAbstract
    },
    {
        path: '/edit-abstract/:id', name: 'EditAbstract', component: EditAbstract
    },
    {
        path: '/confirm-schedule/:id', name: 'ConfirmSchedule', component: ConfirmSchedule
    },
    {
        path: '/book-of-abstracts/:id', name: 'BookOfAbstracts', component: BookOfAbstracts
    },
    {
        path: '/get-certificates/:id', name: 'GetCertificates', component: GetCertificates
    },
    {
        path: '/print/:id', name: 'PaperPrint', component: PaperPrint
    },
    {
        path: '/account-reset/:code', name: 'AccountReset', component: AccountReset
    },
    {
        path: '/manage', name: 'ManageDashboard', component: ManageDashboard, meta: { title: 'Dashboard' },
        children: [
			{
                path: 'users', name: 'UserList', component: UserList, meta: { title: 'Users' },
				children: [
                    {
                        path: 'create', name: 'UserCreate', component: UserCreate, meta: { title: 'Create' }
                    },
                    {
                        path: ':id', name: 'UserEdit', component: UserEdit, meta: { title: 'Edit' }
                    }
				]
            },
            {
                path: 'conferences', name: 'ConferenceList', component: ConferenceList, meta: { title: 'Conferences' },
                children: [
                    {
                        path: 'create', name: 'ConferenceCreate', component: ConferenceCreate, meta: { title: 'Create' }
                    },
                    {
                        path: ':id', name: 'ConferenceEdit', component: ConferenceEdit, meta: { title: 'Edit' }
                    }
                ]
            },
            {
                path: 'topics', name: 'TopicList', component: TopicList, meta: { title: 'Topics' },
                children: [
                    {
                        path: 'create', name: 'TopicCreate', component: TopicCreate, meta: { title: 'Create' }
                    },
                    {
                        path: ':id', name: 'TopicEdit', component: TopicEdit, meta: { title: 'Edit' }
                    }
                ]
            },
            {
                path: 'papers/:conf', name: 'PaperList', component: PaperList, meta: { title: 'Papers' },
                children: [
                    {
                        path: ':id', name: 'PaperView', component: PaperView, meta: { title: 'View' },
                        children: [
                            {
                                path: 'edit', name: 'PaperEdit', component: PaperEdit, meta: { title: 'Edit' }
                            }
                        ]
                    }
                ]
            },
            {
                path: 'invitations', name: 'InvitationList', component: InvitationList, meta: { title: 'Invitations' },
                children: [
                    {
                        path: 'create', name: 'InvitationCreate', component: InvitationCreate, meta: { title: 'Create' }
                    },
                    {
                        path: ':id', name: 'InvitationEdit', component: InvitationEdit, meta: { title: 'Edit' }
                    }
                ]
            },
            {
                path: 'certificates', name: 'CertificateList', component: CertificateList, meta: { title: 'Certificates' },
                children: [
                    {
                        path: 'create', name: 'CertificateCreate', component: CertificateCreate, meta: { title: 'Create' }
                    },
                    {
                        path: ':id', name: 'CertificateEdit', component: CertificateEdit, meta: { title: 'Edit' }
                    }
                ]
            },
            {
                path: 'change-password', name: 'AccountChangePassword', component: AccountChangePassword, meta: { title: 'Change Password' }
            },
            {
                path: 'regparsers', name: 'RegparserList', component: RegparserList, meta: { title: 'Regparsers' },
                children: [
                    {
                        path: 'create', name: 'RegparserCreate', component: RegparserCreate, meta: { title: 'Create' }
                    },
                    {
                        path: ':id', name: 'RegparserEdit', component: RegparserEdit, meta: { title: 'Edit' }
                    }
                ]
            },
            {
                path: 'registrations/:conf', name: 'RegistrationList', component: RegistrationList, meta: { title: 'Registrations' },
                children: [
                    {
                        path: 'create', name: 'RegistrationCreate', component: RegistrationCreate, meta: { title: 'Create' }
                    },
                    {
                        path: ':id', name: 'RegistrationEdit', component: RegistrationEdit, meta: { title: 'Edit' }
                    }
                ]
            },
            {
                path: 'workshops', name: 'WorkshopList', component: WorkshopList, meta: { title: 'Workshops' },
                children: [
                    {
                        path: 'create', name: 'WorkshopCreate', component: WorkshopCreate, meta: { title: 'Create' }
                    },
                    {
                        path: 'registrations/:id', name: 'WorkshopRegistrationList', component: WorkshopRegistrationList, meta: { title: 'Registrations' },
                        children: [
                            {
                                path: 'create', name: 'WorkshopRegistrationCreate', component: WorkshopRegistrationCreate, meta: { title: 'Create' }
                            },
                            {
                                path: ':id', name: 'WorkshopRegistrationEdit', component: WorkshopRegistrationEdit, meta: { title: 'Edit' }
                            }
                        ]
                    },
                    {
                        path: ':id', name: 'WorkshopEdit', component: WorkshopEdit, meta: { title: 'Edit' }
                    }
                ]
            }
        ]
    },
    {
        path: '*', redirect: '/'
    }
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
});

export default router
