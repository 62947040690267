<template>
	<div>
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<error-list v-if="page.errors.length > 0" :errors="page.errors"></error-list>
			<b-container v-else class="pb-5 boa" id="boatop">
				<div v-if="data.sessions.length < 1" class="pt-5">
					<h1>{{data.conference.name}}</h1>

					<p>
						The Book of Abstracts for this conference is currently unavailable. 
					</p>
				</div>
				<div v-else>
					<div class="sticky-top py-3 bg-white">
						<b-dropdown text="Scroll to content..." class="border" variant="light" size="lg" menu-class="agenda-dropdown">
							<b-dropdown-item href="#" v-scroll-to="'#boatop'">Foreword</b-dropdown-item>
							<b-dropdown-item href="#" v-scroll-to="'#boasc'">Scientific Committee</b-dropdown-item>
							<b-dropdown-divider></b-dropdown-divider>
							<b-dropdown-group v-for="g in data.sessions" :key="g.day" :header="g.day">
								<b-dropdown-item v-for="s in g.sessions" :key="s.link" href="#" v-scroll-to="`#${s.link}`">
									<span class="d-inline-block" style="width:6.5rem">{{s.time}}</span> Session {{s.session}}: {{s.name}}
								</b-dropdown-item>
							</b-dropdown-group>
						</b-dropdown>
					</div>

					<div v-if="!isNullOrEmpty(data.conference.bookIntroText)" v-html="data.conference.bookIntroText"></div>
					<div v-else>
						<h1>{{data.conference.name}} - Book of Abstracts</h1>
					</div>

					<h2 class="border bg-light p-3 mb-3 h3" id="boasc">Scientific Committee</h2>
					<ul>
						<li v-for="(c, i) in data.committee" :key="i">{{c.name}} &mdash; {{c.affiliation}}</li>
					</ul>

					<div v-for="g in data.sessions" :key="g.day">
						<h2 class="mt-4 text-center h6 font-weight-light font-italic">{{g.day}}</h2>

						<div v-for="s in g.sessions" :key="s.link">
							<h3 class="border bg-light p-3 mb-3" :id="s.link">Session {{s.session}}: {{s.name}}</h3>

							<div v-for="p in s.papers" :key="p.id" class="p-3 paper">
								<h4 class="font-weight-light mb-3">{{p.title}}</h4>
								<div v-html="authorHtml(p)"></div>

								<h5 class="mt-4 font-weight-light">Abstract</h5>
								<div v-html="p.abstract"></div>

								<div class="mt-4" v-if="!isNullOrEmpty(p.keywords)">
									<h5 class="font-weight-light">Keywords</h5>
									<p>{{p.keywords}}</p>
								</div>
								<div class="pt-3"></div>

								<hr class="mt-4" />
							</div>
						</div>
					</div>


				</div>
			</b-container>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'BookOfAbstracts',
		data() {
			return {
				id: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					saving: false,
					saveErrors: [],
					confirmed: false
				},
				data: {
					conference: {},
					committee: [],
					sessions: []
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.id = this.$route.params.id;

				try {
					const response = await this.$http.get(`book/${this.id}`);
					this.log(response.data);
					this.data = response.data;

				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			authorHtml(paper) {
				var html = '<p class="authors mb-1">';
				var orderHtml = '<ol class="mb-1">';

				var cnt = paper.authors.length;
				var affiliationIndex = 1;
				for (var i = 0; i < cnt; i++) {
					var a = paper.authors[i];
					var comma = i === cnt - 1 ? '' : ', ';
					var corresponding = a.isCorresponding ? '*' : '';
					var affiliation = '';
					if (!this.isNullOrEmpty(a.affiliation) || a.isCorresponding) {
						affiliation = affiliationIndex;
						affiliationIndex++;

						var correspondingNote = a.isCorresponding ? `Email: ${a.email} (corresponding author)` : '';
						orderHtml += `<li>${a.affiliation} ${correspondingNote}</li >`;
					}

					html += `${a.firstName} ${a.lastName}<sup>${corresponding}${affiliation}</sup>${comma}`;
				}
				html += '</p>';
				orderHtml += '</ol>';

				return html + orderHtml;
			}
		},
		computed: {
			isConfirmed() {
				return this.page.confirmed || this.data.isScheduleConfirmed;
			}
		}
	}
</script>
