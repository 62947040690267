<template>
	<topic-form></topic-form>
</template>

<script>
	import TopicForm from '@/views/manage/topics/TopicForm';

	export default {
		name: 'TopicCreate',
		components: {
			TopicForm
		}
	}
</script>