<template>
	<auth-container :page="page" require-admin>
		<h2 class="mb-4">Upload CSV of names to receive certificates</h2>
		<error-list :errors="page.saveErrors"></error-list>
		<success-alert ref="savedAlert" text="Changes saved." />

		<p>Or use the button at the bottom to create from registration data.</p>

		<b-form-group>
			<b-form-select v-model="form.conferenceId" :options="options.conferences">
				<template v-slot:first>
					<option :value="null" disabled>-- Select a conference --</option>
				</template>
			</b-form-select>
		</b-form-group>

		<div v-if="!isNullOrEmpty(form.conferenceId)">
			<vue-dropzone :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>
		</div>

		<p>
			<code>Name, Email, AltEmail, ConferenceAttendee, Presenter, WorkshopAttendee, WorkshopName</code>
		</p>

		<fixed-action-bar :cols-lg="10" :offset-lg="2">
			<save-button :saving="page.saving" class="mr-2" text="Create From Registrations" @click.native="addFromRegistrations" />
			<back-button class="btn btn-secondary mr-2" />
		</fixed-action-bar>

	</auth-container>
</template>

<script>
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';

	export default {
		name: 'CertificateCreate',
		components: {
			vueDropzone: vue2Dropzone
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: []
				},
				form: {
					conferenceId: null
				},
				options: {
					conferences: []
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`invitations/conferences`, this.getTokenHeader());
					this.log(response.data);
					this.options.conferences = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async fileUploaded(file, response) {
				this.log(file);
				this.log(response);
				this.page.saveErrors = [];
				this.$refs.savedAlert.startAlert();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.saveErrors.push(message);
			},
			async addFromRegistrations() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.isNullOrEmpty(this.form.conferenceId)) {
					this.page.saveErrors.push('Please select a conference.');
				} else {
					try {
						const response = await this.$http.post(`certificates/fromregistrations/${this.form.conferenceId}`, {}, this.getTokenHeader());
						this.log(response.data);
						this.$refs.savedAlert.startAlert();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
						console.log(error);
					}
				}

				this.page.saving = false;
			}
		},
		computed: {
			dropzoneOptions() {
				return {
					url: `/api/certificates/upload/${this.form.conferenceId}`,
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.csv'
				}
			}
		}
	}
</script>