<template>
	<div id="content-frame">
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading" class="max-w-400 mx-auto py-4">
			<error-list :errors="page.errors"></error-list>

			<div v-if="account.reset">
				<div>
					Your account has been reset! Please log in below.
				</div>
				<login-form></login-form>
			</div>
			<div v-else>
				<h1 class="h3 my-3 font-weight-normal">Reset your password</h1>
				<b-form @submit.prevent="resetPassword" class="my-4">
					<b-form-group label="Email address" :invalid-feedback="requiredFeedback($v.form.email)">
						<b-form-input v-model="form.email" type="email" :state="getValidState($v.form.email)"></b-form-input>
					</b-form-group>

					<b-form-group label="Enter a new password" description="Minimum 10 characters with a number and special character">
						<b-form-input v-model="form.password" type="password" :state="getValidState($v.form.password)"></b-form-input>
						<b-form-invalid-feedback v-if="!$v.form.password.required">Required</b-form-invalid-feedback>
						<b-form-invalid-feedback v-if="!$v.form.password.strongPassword">Must be at least 10 characters with a number and special character</b-form-invalid-feedback>
					</b-form-group>

					<b-form-group label="Confirm your new password">
						<b-form-input v-model="form.comparePassword" type="password" :state="getValidState($v.form.comparePassword)"></b-form-input>
						<b-form-invalid-feedback v-if="!$v.form.comparePassword.required">Required</b-form-invalid-feedback>
						<b-form-invalid-feedback v-if="!$v.form.comparePassword.sameAsPassword">Passwords must match</b-form-invalid-feedback>
					</b-form-group>

					<div class="mt-4">
						<save-button :saving="page.saving" text="Reset password" size="lg" />
					</div>
				</b-form>
			</div>
		</div>
	</div>
</template>

<script>
	import { required, sameAs } from 'vuelidate/lib/validators';

	export default {
		name: 'AccountConfirm',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					saving: false
				},
				form: {
					code: '',
					userName: '',
					password: '',
					comparePassword: ''
				},
				account: {
					reset: false
				}
			}
		},
		validations: {
			form: {
				email: { required },
				password: {
					required,
					strongPassword(password) {
						return (
							/[a-z]/.test(password) && // checks for a-z
							/[0-9]/.test(password) && // checks for 0-9
							/\W|_/.test(password) && // checks for special char
							password.length >= 10
						);
					}
				},
				comparePassword: { required, sameAsPassword: sameAs('password') }
			}
		},
		methods: {
			resetPassword: async function () {
				this.page.errors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					var data = {
						code: this.$route.params.code,
						email: this.form.email,
						password: this.form.password
					}

					try {
						const response = await this.$http.post('account/resetpassword', data);
						this.log(response.data);
						this.account.reset = true;
					} catch (error) {
						this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			}
		}
	}
</script>
