<template>
	<auth-container :page="page" require-admin>
		<h2 class="mb-4">Edit conference</h2>

		<b-tabs v-model="tabIndex" content-class="mt-4" class="page-nav-tabs">
			<b-tab title="Settings">
				<error-list :errors="page.form.errors"></error-list>
				<success-alert ref="formSavedAlert" text="Changes saved." />

				<b-form>
					<b-form-group label="Conference Title" :invalid-feedback="requiredFeedback($v.form.name)">
						<b-form-input v-model.trim="$v.form.name.$model" type="text" :state="getValidState($v.form.name)"></b-form-input>
					</b-form-group>

					<b-row>
						<b-col md>
							<b-form-group label="Year" :invalid-feedback="requiredFeedback($v.form.year)">
								<b-form-input v-model.trim="$v.form.year.$model" type="number" :state="getValidState($v.form.year)"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Start Date" :invalid-feedback="requiredFeedback($v.form.startDate)">
								<b-form-input v-model.trim="$v.form.startDate.$model" type="date" :state="getValidState($v.form.startDate)"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="End Date" :invalid-feedback="requiredFeedback($v.form.endDate)">
								<b-form-input v-model.trim="$v.form.endDate.$model" type="date" :state="getValidState($v.form.endDate)"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>

					<b-form-group label="Conference Location" :invalid-feedback="requiredFeedback($v.form.location)">
						<b-form-input v-model.trim="$v.form.location.$model" type="text" :state="getValidState($v.form.location)"></b-form-input>
					</b-form-group>

					<b-form-group label="Website" :invalid-feedback="requiredFeedback($v.form.website)">
						<b-form-input v-model.trim="$v.form.website.$model" type="text" :state="getValidState($v.form.website)"></b-form-input>
					</b-form-group>

					<b-form-group label="URL Tag" :invalid-feedback="requiredFeedback($v.form.urlModifier)">
						<b-form-input v-model.trim="$v.form.urlModifier.$model" type="text" :state="getValidState($v.form.urlModifier)"></b-form-input>
					</b-form-group>

					<b-form-group label="Late Access Code" :invalid-feedback="requiredFeedback($v.form.lateAccessCode)">
						<b-form-input v-model.trim="$v.form.lateAccessCode.$model" type="text" :state="getValidState($v.form.lateAccessCode)"></b-form-input>
					</b-form-group>

					<b-form-group class="mt-4">
						<b-checkbox v-model.trim="$v.form.isClosed.$model">Close abstract submission</b-checkbox>
					</b-form-group>

					<b-form-group>
						<b-checkbox v-model.trim="$v.form.isOpenForEdits.$model">Abstracts open for editing by user</b-checkbox>
					</b-form-group>

					<b-form-group>
						<b-checkbox v-model.trim="$v.form.disableRemotePresentation.$model">Disable remote presentation option</b-checkbox>
					</b-form-group>

					<b-form-group>
						<b-checkbox v-model.trim="$v.form.showInNav.$model">Show conference in navigation</b-checkbox>
					</b-form-group>

					<b-form-group label="Abstract Approval Notice" :invalid-feedback="requiredFeedback($v.form.abstractApprovalNotice)">
						<b-form-textarea v-model.trim="$v.form.abstractApprovalNotice.$model" rows="5" :state="getValidState($v.form.abstractApprovalNotice)"></b-form-textarea>
					</b-form-group>

					<b-form-group label="Presenter Registration Deadline" :invalid-feedback="requiredFeedback($v.form.presenterRegistrationDeadline)">
						<b-form-input v-model.trim="$v.form.presenterRegistrationDeadline.$model" type="date" :state="getValidState($v.form.presenterRegistrationDeadline)"></b-form-input>
					</b-form-group>

					<hr class="my-4" />

					<b-form-group label="Invitation Letter Contact" :invalid-feedback="requiredFeedback($v.form.invitationContact)">
						<b-form-textarea v-model.trim="$v.form.invitationContact.$model" rows="3" :state="getValidState($v.form.invitationContact)"></b-form-textarea>
					</b-form-group>

					<b-form-group label="Invitation Letter Location" :invalid-feedback="requiredFeedback($v.form.invitationLocation)">
						<b-form-input v-model.trim="$v.form.invitationLocation.$model" type="text" :state="getValidState($v.form.invitationLocation)"></b-form-input>
					</b-form-group>

					<b-form-group label="Organizer Name" :invalid-feedback="requiredFeedback($v.form.organizerName)">
						<b-form-textarea v-model.trim="$v.form.organizerName.$model" rows="3" :state="getValidState($v.form.organizerName)"></b-form-textarea>
					</b-form-group>

					<b-form-group label="Organizer Affiliation" :invalid-feedback="requiredFeedback($v.form.organizerAffiliation)">
						<b-form-input v-model.trim="$v.form.organizerAffiliation.$model" type="text" :state="getValidState($v.form.organizerAffiliation)"></b-form-input>
					</b-form-group>

					<hr class="my-4" />

					<b-form-group label="Book of Abstracts Introduction Content" :invalid-feedback="requiredFeedback($v.form.bookIntroText)">
						<tinymce-editor :api-key="tinymce.api" :init="tinymce.init" v-model.trim="$v.form.bookIntroText.$model" :state="getValidState($v.form.bookIntroText)"></tinymce-editor>
					</b-form-group>

					<b-form-group label="Timezone Description Text" :invalid-feedback="requiredFeedback($v.form.timezoneDescription)">
						<b-form-input v-model.trim="$v.form.timezoneDescription.$model" type="text" :state="getValidState($v.form.timezoneDescription)"></b-form-input>
					</b-form-group>

					<b-form-group label="Timezone Link" :invalid-feedback="requiredFeedback($v.form.timezoneLink)">
						<b-form-input v-model.trim="$v.form.timezoneLink.$model" type="text" :state="getValidState($v.form.timezoneLink)"></b-form-input>
					</b-form-group>

					<b-form-group label="Certificate Local Logo Position Style" :invalid-feedback="requiredFeedback($v.form.certificatePositionStyle)">
						<b-form-input v-model.trim="$v.form.certificatePositionStyle.$model" type="text" :state="getValidState($v.form.certificatePositionStyle)"></b-form-input>
					</b-form-group>

					<b-form-group label="Certificate Local Logo Size Style" :invalid-feedback="requiredFeedback($v.form.certificateSizeStyle)">
						<b-form-input v-model.trim="$v.form.certificateSizeStyle.$model" type="text" :state="getValidState($v.form.certificateSizeStyle)"></b-form-input>
					</b-form-group>
				</b-form>
			</b-tab>
			<b-tab title="Topics">
				<error-list :errors="page.topics.errors"></error-list>
				<success-alert ref="topicsSavedAlert" text="Changes saved." />

				<b-form>
					<div class="checklist" style="max-height:none">
						<b-form-checkbox-group v-model="data.topics" stacked>
							<div class="item" v-for="(o, i) in options.topics" :key="i">
								<b-form-checkbox :value="o.id">{{o.name}}</b-form-checkbox>
							</div>
						</b-form-checkbox-group>
					</div>
				</b-form>
			</b-tab>
			<b-tab title="Committee">
				<error-list :errors="page.committee.errors"></error-list>
				<success-alert ref="committeeSavedAlert" text="Changes saved." />

				<p>
					<b-button @click="page.committee.showList = true" variant="primary">Listing</b-button>
					<b-button @click="page.committee.showHtml = true" variant="primary" class="ml-1">Web Code</b-button>
					<b-button v-if="options.conferences.length > 0" @click="page.import.show = true" variant="info" class="ml-1">Import</b-button>

					<a :href="`/data/scientificcommittee/${form.urlModifier}?access_token=${localStorageToken}`" class="btn btn-info ml-1">
						Download
						<font-awesome-icon :icon="['fas', 'file-excel']" fixed-width class="ml-1" />
					</a>
				</p>

				<b-table-simple responsive small bordered striped>
					<b-thead head-variant="light">
						<b-tr>
							<b-th sticky-column>User</b-th>
							<b-th>Topics</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr v-for="(u, i) in options.users" :key="i">
							<b-td sticky-column>
								{{u.name}} <small class="text-muted">{{u.affiliation}}</small>
							</b-td>
							<b-td>
								<b-form-checkbox-group v-model="selected.users[u.id]"
													   :options="options.conferenceTopics"
													   value-field="id"
													   text-field="shortName"
													   disabled-field="notEnabled"></b-form-checkbox-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</b-tab>
			<b-tab title="Files">
				<error-list :errors="page.invitation.errors"></error-list>

				<p>
					Upload invitation letter signature ({{signatureDropzoneOptions.acceptedFiles}})
				</p>
				<vue-dropzone id="sigFileUpload" :options="signatureDropzoneOptions" @vdropzone-success="signatureFileUploaded" @vdropzone-error="uploadError"></vue-dropzone>
				<div v-if="!isNullOrEmpty(data.invitation.signature)">
					<img :src="data.invitation.signature" alt="Signature" style="max-width:200px" />
				</div>

				<p>
					Upload invitation letter logo ({{logoDropzoneOptions.acceptedFiles}})
				</p>
				<vue-dropzone id="logoFileUpload" :options="logoDropzoneOptions" @vdropzone-success="logoFileUploaded" @vdropzone-error="uploadError"></vue-dropzone>
				<div v-if="!isNullOrEmpty(data.invitation.logo)">
					<img :src="data.invitation.logo" alt="Logo" style="max-width:200px" />
				</div>
			</b-tab>
			<b-tab title="Registrations">
				<p>
					Number registered: <strong class="text-info">{{data.numRegistered}}/{{data.numApproved}}</strong> approved papers
				</p>
				<error-list :errors="page.registrations.errors"></error-list>
				<success-alert ref="registrationsSavedAlert" text="Changes saved." />

				<b-form>
					<b-form-group label="Set registered email addresses (one per line)" :invalid-feedback="requiredFeedback($v.registrations.value)">
						<b-form-textarea v-model.trim="$v.registrations.value.$model" rows="10" :state="getValidState($v.registrations.value)"></b-form-textarea>
					</b-form-group>
				</b-form>

				<b-form-group label="Additional note to email unregistered participants (optional)">
					<b-form-textarea v-model="page.registrations.additionalNote" rows="10"></b-form-textarea>
				</b-form-group>
			</b-tab>
			<b-tab title="Sessions">
				<error-list :errors="page.sessions.errors"></error-list>
				<success-alert ref="sessionsSavedAlert" text="Changes saved." />

				<b-table-simple responsive small bordered striped>
					<b-thead head-variant="light">
						<b-tr>
							<b-th>Name</b-th>
							<b-th>Topic</b-th>
							<b-th>Room</b-th>
							<b-th>Start</b-th>
							<b-th>End</b-th>
							<b-th>Moderator</b-th>
							<b-th>Email</b-th>
							<b-th>Affiliation</b-th>
							<b-th></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr v-for="(s, x) in data.sessions" :key="x">
							<b-td><b-form-input type="text" v-model="s.name" size="sm" /></b-td>
							<b-td>
								<b-form-select v-model="s.topicId" size="sm">
									<b-form-select-option v-for="t in options.conferenceTopics" :key="t.id" :value="t.id">{{t.shortName}}</b-form-select-option>
								</b-form-select>
							</b-td>
							<b-td><b-form-input type="text" v-model="s.room" size="sm" /></b-td>
							<b-td><b-form-input type="datetime-local" v-model="s.startTime" size="sm" /></b-td>
							<b-td><b-form-input type="datetime-local" v-model="s.endTime" size="sm" /></b-td>
							<b-td><b-form-input type="text" v-model="s.moderatorName" size="sm" /></b-td>
							<b-td><b-form-input type="email" v-model="s.moderatorEmail" size="sm" /></b-td>
							<b-td><b-form-input type="text" v-model="s.moderatorAffiliation" size="sm" /></b-td>
							<b-td><font-awesome-icon :icon="['fas', 'times']" class="text-danger pointer" @click="deleteSession(s)" v-b-tooltip.hover.right="'Delete'" /></b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
				<b-button variant="primary" @click="addSession">Add</b-button>
			</b-tab>
			<b-tab title="Assignments">
				<error-list :errors="page.assignments.errors"></error-list>
				<success-alert ref="assignmentsSavedAlert" text="Changes saved." />

				<b-table-simple responsive small bordered striped>
					<b-thead head-variant="light">
						<b-tr>
							<b-th>Session</b-th>
							<b-th>Order</b-th>
							<b-th>Conf.</b-th>
							<b-th>Poster</b-th>
							<b-th>Remote</b-th>
							<b-th>Email</b-th>
							<b-th>Presenter</b-th>
							<b-th>Title</b-th>
							<b-th></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr v-for="p in data.assignments" :key="p.id">
							<b-td>
								<b-form-select v-model="p.sessionId" size="sm">
									<b-form-select-option v-for="s in data.sessions" :key="s.id" :value="s.id">{{s.name}}</b-form-select-option>
								</b-form-select>
							</b-td>
							<b-td><b-form-input type="number" v-model.number="p.orderInSession" size="sm" /></b-td>
							<b-td class="align-middle text-center"><font-awesome-icon v-if="p.isScheduleConfirmed" :icon="['fas', 'check']" /></b-td>
							<b-td class="align-middle text-center"><font-awesome-icon v-if="p.isPoster" :icon="['fas', 'check']" /></b-td>
							<b-td class="align-middle text-center"><font-awesome-icon v-if="p.isRemote" :icon="['fas', 'check']" /></b-td>
							<b-td class="align-middle">{{p.presenterEmail}}</b-td>
							<b-td class="align-middle"><router-link :to="`/confirm-schedule/${p.accessCode}`" target="_blank">{{p.presenterFirstName}} {{p.presenterLastName}}</router-link></b-td>
							<b-td class="align-middle"><router-link :to="`/manage/papers/${form.urlModifier}/${p.id}`" target="_blank">{{p.title}}</router-link></b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</b-tab>
			<b-tab title="Send Email">
				<error-list :errors="page.emails.errors"></error-list>
				<success-alert ref="emailsSavedAlert" text="Emails sent." />

				<b-form>
					<b-form-group label="Send To" :invalid-feedback="requiredFeedback($v.emailForm.sendGroup)">
						<b-form-select v-model.trim="$v.emailForm.sendGroup.$model" :state="getValidState($v.emailForm.sendGroup)">
							<b-form-select-option :value="0">Registered Participants</b-form-select-option>
							<b-form-select-option :value="1">Registered Presenters</b-form-select-option>
							<b-form-select-option :value="2">Unregistered Presenters</b-form-select-option>
							<b-form-select-option :value="3">Scientific Committee</b-form-select-option>
							<b-form-select-option :value="4">Conference Participants</b-form-select-option>
							<b-form-select-option :value="5">Conference In-Person</b-form-select-option>
							<b-form-select-option :value="6">Workshop Participants</b-form-select-option>
							<b-form-select-option :value="7">Conference In-Person No Dinner</b-form-select-option>
						</b-form-select>
					</b-form-group>

					<b-form-group label="Subject" :invalid-feedback="requiredFeedback($v.emailForm.subject)">
						<b-form-input v-model.trim="$v.emailForm.subject.$model" type="text" :state="getValidState($v.emailForm.subject)"></b-form-input>
					</b-form-group>

					<b-form-group label="Message" :invalid-feedback="requiredFeedback($v.emailForm.body)">
						<b-form-textarea v-model.trim="$v.emailForm.body.$model" rows="6" :state="getValidState($v.emailForm.body)"></b-form-textarea>
					</b-form-group>

					<b-form-group label="BCC" :invalid-feedback="requiredFeedback($v.emailForm.bcc)">
						<b-form-input v-model.trim="$v.emailForm.bcc.$model" type="text" :state="getValidState($v.emailForm.bcc)"></b-form-input>
					</b-form-group>
				</b-form>
			</b-tab>
		</b-tabs>

		<fixed-action-bar :cols-lg="10" :offset-lg="2">
			<save-button v-if="tabIndex == 0" :saving="page.form.saving" @click.native="save" class="mr-2" :disabled="!$v.form.$anyDirty" />
			<save-button v-if="tabIndex == 1" :saving="page.topics.saving" @click.native="saveTopics" class="mr-2" />
			<save-button v-if="tabIndex == 2" :saving="page.committee.saving" @click.native="saveCommittee" class="mr-2" />
			<save-button v-if="tabIndex == 4" :saving="page.registrations.saving" @click.native="saveRegistrations" class="mr-2" :disabled="!$v.registrations.$anyDirty" />
			<save-button v-if="tabIndex == 4" :saving="page.registrations.saving" @click.native="emailUnregistered" class="mr-2" text="Email Unregistered" variant="info" />
			<save-button v-if="tabIndex == 5" :saving="page.sessions.saving" @click.native="saveSessions" class="mr-2" :disabled="data.sessions.length < 1" />
			<save-button v-if="tabIndex == 6" :saving="page.assignments.saving" @click.native="saveAssignments" class="mr-2" :disabled="data.sessions.length < 1 || data.numApproved < 1" />
			<save-button v-if="tabIndex == 6" :saving="page.assignments.saving" @click.native="emailAssignments" class="mr-2" text="Email Assignments" variant="info" />
			<save-button v-if="tabIndex == 7" :saving="page.emails.saving" @click.native="sendEmailForm" class="mr-2" text="Send Email" />
			<b-button v-if="tabIndex == 0" variant="info" :to="`/manage/papers/${form.urlModifier}`" class="mr-2">Go to Papers</b-button>
			<back-button class="btn btn-secondary mr-2" />
			<b-button v-if="options.numPapers === 0" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
			<save-button v-if="tabIndex == 4" :saving="page.registrations.saving" @click.native="unregisterAll" class="ml-auto" text="Unregister All" variant="danger" />
		</fixed-action-bar>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete this conference?
				This action is permanent and cannot be undone.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>

		<b-modal v-model="page.committee.showList" size="lg" title="Scientific Committee" scrollable>
			<div v-for="(t, i) in data.committeeList" :key="i">
				<h5>{{t.topic}}</h5>
				<ul>
					<li v-for="(u, j) in t.users" :key="j">{{u}}</li>
				</ul>
			</div>
		</b-modal>

		<b-modal v-model="page.committee.showHtml" size="lg" title="Scientific Committee Web Code" scrollable>
			<b-form-textarea v-model="data.committeeHtml" rows="15"></b-form-textarea>
		</b-modal>

		<b-modal v-model="page.import.show" size="md" title="Import Committee" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.import.errors"></error-list>

			<p>
				Import the scientific committee from a previous conference.
				This will delete the existing committee for this conference.
			</p>

			<p>
				<b-form-select v-model="selected.importFromId" :options="options.conferences" required>
					<template v-slot:first>
						<option :value="null" disabled>-- Select a conference --</option>
					</template>
				</b-form-select>
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.import.saving" @click.native="importCommittee" text="Import" variant="success" />
				<b-button type="button" variant="secondary" @click="page.import.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</auth-container>
</template>

<script>
	import { required, integer } from 'vuelidate/lib/validators';
	import moment from 'moment';
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import Editor from '@tinymce/tinymce-vue';

	export default {
		name: 'ConferenceEdit',
		components: {
			vueDropzone: vue2Dropzone, 'tinymce-editor': Editor
		},
		data() {
			return {
				id: this.$route.params.id,
				signatureDropzoneOptions: {
					url: `/api/conferences/uploadsignature/${this.$route.params.id}`,
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.jpg, .jpeg, .png, .svg, .gif'
				},
				logoDropzoneOptions: {
					url: `/api/conferences/uploadlogo/${this.$route.params.id}`,
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.jpg, .jpeg, .png, .svg, .gif'
				},
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					form: {
						errors: [],
						saving: false
					},
					topics: {
						errors: [],
						saving: false
					},
					committee: {
						errors: [],
						saving: false,
						showList: false,
						showHtml: false
					},
					delete: {
						errors: [],
						saving: false,
						show: false
					},
					import: {
						errors: [],
						saving: false,
						show: false
					},
					invitation: {
						errors: [],
						saving: false
					},
					registrations: {
						errors: [],
						saving: false,
						additionalNote: ''
					},
					sessions: {
						errors: [],
						saving: false
					},
					assignments: {
						errors: [],
						saving: false
					},
					emails: {
						errors: [],
						saving: false
					}
				},
				tabIndex: 0,
				form: {
					name: null,
					year: null,
					location: null,
					website: null,
					urlModifier: null,
					abstractApprovalNotice: null,
					isClosed: false,
					isOpenForEdits: true,
					startDate: null,
					endDate: null,
					lateAccessCode: null,
					invitationContact: null,
					invitationLocation: null,
					organizerAffiliation: null,
					organizerName: null,
					showInNav: false,
					presenterRegistrationDeadline: null,
					timezoneDescription: null,
					timezoneLink: null,
					disableRemotePresentation: false,
					certificatePositionStyle: null,
					certificateSizeStyle: null
				},
				data: {
					topics: [],
					committee: [],
					committeeList: [],
					committeeHtml: null,
					invitation: {
						signature: null,
						logo: null
					},
					sessions: [],
					assignments: []
				},				
				options: {
					topics: [],
					users: [],
					conferences: []
				},				
				selected: {
					committee: [],
					users: [],
					importFromId: null
				},
				registrations: {
					value: null
				},
				emailForm: {
					sendGroup: 0,
					subject: null,
					body: null,
					bcc: null
				},
				tinymce: {
					api: 'umc6fcf2lmc4vpzyrqb2jfcr2slzvqb94kk4ac6wl757gx5b',
					init: {
						height: 400,
						menubar: false,
						plugins: [
							'advlist autolink lists link image charmap print preview anchor',
							'searchreplace visualblocks code fullscreen',
							'insertdatetime media table paste code help wordcount'
						],
						toolbar: 'undo redo | formatselect | bold italic underline | link unlink | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code',
					}
				}
			}
		},
		validations: {
			form: {
				name: { required },
				year: { required, integer },
				location: { required },
				website: { required },
				urlModifier: { required },
				abstractApprovalNotice: { required },
				isClosed: {},
				isOpenForEdits: {},
				startDate: { required },
				endDate: { required },
				lateAccessCode: { required },
				invitationContact: {},
				invitationLocation: {},
				bookIntroText: {},
				organizerAffiliation: {},
				organizerName: {},
				showInNav: {},
				presenterRegistrationDeadline: {},
				timezoneDescription: {},
				timezoneLink: {},
				disableRemotePresentation: {},
				certificatePositionStyle: {},
				certificateSizeStyle: {}
			},
			registrations: {
				value: { required }
			},
			emailForm: {
				sendGroup: { required, integer },
				subject: { required },
				body: { required },
				bcc: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.id = this.$route.params.id;

				try {
					const response = await this.$http.get(`conferences/${this.id}`, this.getTokenHeader());
					this.log(response.data);
					this.form = response.data.conference;
					this.data = response.data.data;
					this.options = response.data.options;

					this.form.startDate = moment(this.form.startDate).format('YYYY-MM-DD');
					this.form.endDate = moment(this.form.endDate).format('YYYY-MM-DD');
					if (!this.isNullOrEmpty(this.form.presenterRegistrationDeadline))
						this.form.presenterRegistrationDeadline = moment(this.form.presenterRegistrationDeadline).format('YYYY-MM-DD');
					this.emailForm.subject = this.form.name;

					for (var i = 0; i < this.options.users.length; i++) {
						var userId = this.options.users[i].id;
						var topics = [];
						if (userId in this.data.committee) {
							topics = this.data.committee[userId];
							this.selected.committee.push(userId);
						}
						this.selected.users[userId] = topics;
					}

				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.form.errors = [];
				this.page.form.saving = true;

				if (this.$v.form.$invalid) {
					this.page.form.errors.push('Please fix the errors below and try again.');
					this.log(this.$v);
				} else {
					try {
						const response = await this.$http.put(`conferences/${this.id}`, this.form, this.getTokenHeader());
						this.log(response.data);
						this.$refs.formSavedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.form.errors = this.logError(error);
					}
				}

				this.page.form.saving = false;
			},
			async saveTopics() {
				this.page.topics.errors = [];
				this.page.topics.saving = true;

				try {
					var data = {
						items: this.data.topics
					}
					const response = await this.$http.put(`conferences/topics/${this.id}`, data, this.getTokenHeader());
					this.log(response.data);
					this.$refs.topicsSavedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.topics.errors = this.logError(error);
				}

				this.page.topics.saving = false;
			},
			async saveCommittee() {
				this.page.committee.errors = [];
				this.page.committee.saving = true;

				try {
					var data = [];
					for (var i = 0; i < this.options.users.length; i++) {
						var userId = this.options.users[i].id;
						if (this.selected.users[userId].length > 0) {
							data.push({
								userId: userId,
								topics: this.selected.users[userId]
							});
						}
					}
					this.log(data);

					const response = await this.$http.put(`conferences/committee/${this.id}`, data, this.getTokenHeader());
					this.log(response.data);
					this.$refs.committeeSavedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.committee.errors = this.logError(error);
				}

				this.page.committee.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`conferences/${this.id}`, this.getTokenHeader());
					this.log(response.data);
					this.$router.push({ name: 'ConferenceList' }).catch(err => { this.log(err); });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			async importCommittee() {
				this.page.import.errors = [];
				this.page.import.saving = true;

				if (this.selected.importFromId === null) {
					this.page.import.errors.push('Please select a conference.');
				} else {
					try {
						const response = await this.$http.put(`conferences/import/${this.id}/${this.selected.importFromId}`, {}, this.getTokenHeader());
						this.log(response.data);
						await this.get();
						this.$refs.importSavedAlert.startAlert();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.import.errors = this.logError(error);
					}
				}

				this.page.import.saving = false;
			},
			async signatureFileUploaded(file, response) {
				this.log(file);
				this.log(response);
				this.page.invitation.errors = [];
				await this.get();
			},
			async logoFileUploaded(file, response) {
				this.log(file);
				this.log(response);
				this.page.invitation.errors = [];
				await this.get();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.invitation.errors.push(message);
			},
			async saveRegistrations() {
				this.page.registrations.errors = [];
				this.page.registrations.saving = true;

				if (this.$v.registrations.$invalid) {
					this.page.registrations.errors.push('Please fix the errors below and try again.');
					this.log(this.$v);
				} else {
					try {
						const response = await this.$http.put(`conferences/registrations/${this.id}`, this.registrations, this.getTokenHeader());
						this.log(response.data);
						this.$refs.registrationsSavedAlert.startAlert();
						this.$v.$reset();
						await this.get();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.registrations.errors = this.logError(error);
					}
				}

				this.page.registrations.saving = false;
			},
			async unregisterAll() {
				this.page.registrations.errors = [];
				this.page.registrations.saving = true;

				try {
					const response = await this.$http.put(`conferences/unregisterall/${this.id}`, {}, this.getTokenHeader());
					this.log(response.data);
					this.$refs.registrationsSavedAlert.startAlert();
					this.$v.$reset();
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.registrations.errors = this.logError(error);
				}

				this.page.registrations.saving = false;
			},
			async emailUnregistered() {
				this.page.registrations.errors = [];
				this.page.registrations.saving = true;

				try {
					const response = await this.$http.post(`conferences/registrationreminders/${this.id}`, { value: this.page.registrations.additionalNote }, this.getTokenHeader());
					this.log(response.data);
					this.$refs.registrationsSavedAlert.startAlert();
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.registrations.errors = this.logError(error);
				}

				this.page.registrations.saving = false;
			},
			addSession() {
				this.data.sessions.push({
					id: 0,
					conferenceId: Number(this.id),
					name: null,
					topicId: null,
					room: null,
					startTime: null,
					endTime: null,
					moderatorName: null,
					moderatorEmail: null,
					moderatorAffiliation: null
				});
			},
			deleteSession(element) {
				this.data.sessions.splice(this.data.sessions.indexOf(element), 1);
			},
			async saveSessions() {
				this.page.sessions.errors = [];
				this.page.sessions.saving = true;

				try {
					const response = await this.$http.put(`conferences/sessions/${this.id}`, this.data.sessions, this.getTokenHeader());
					this.log(response.data);
					this.$refs.sessionsSavedAlert.startAlert();
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.sessions.errors = this.logError(error);
				}

				this.page.sessions.saving = false;
			},
			async saveAssignments() {
				this.page.assignments.errors = [];
				this.page.assignments.saving = true;

				try {
					const response = await this.$http.put(`conferences/assignments/${this.id}`, this.data.assignments, this.getTokenHeader());
					this.log(response.data);
					this.$refs.assignmentsSavedAlert.startAlert();
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.assignments.errors = this.logError(error);
				}

				this.page.assignments.saving = false;
			},
			async emailAssignments() {
				this.page.assignments.errors = [];
				this.page.assignments.saving = true;

				try {
					const response = await this.$http.post(`conferences/scheduleemails/${this.id}`, {}, this.getTokenHeader());
					this.log(response.data);
					this.$refs.assignmentsSavedAlert.startAlert();
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.assignments.errors = this.logError(error);
				}

				this.page.assignments.saving = false;
			},
			async sendEmailForm() {
				this.page.emails.errors = [];
				this.page.emails.saving = true;

				try {
					const response = await this.$http.post(`conferences/sendemail/${this.id}`, this.emailForm, this.getTokenHeader());
					this.log(response.data);
					this.$refs.emailsSavedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.emails.errors = this.logError(error);
				}

				this.page.emails.saving = false;
			}
		}
	}
</script>