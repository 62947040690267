<template>
	<auth-container :page="page" require-admin>
		<h2 class="mb-4">{{isUpdate ? 'Edit' : 'Add a new'}} registration</h2>
		<error-list :errors="page.saveErrors"></error-list>
		<success-alert ref="savedAlert" text="Changes saved." />
		<success-alert ref="emailAlert" text="Invoice email sent." />

		<b-form @submit.prevent="save">
			<b-form-group :invalid-feedback="requiredFeedback($v.form.workshopId)">
				<b-form-select v-model="$v.form.workshopId.$model" :options="options.workshops" :state="getValidState($v.form.workshopId)">
					<template #first>
						<b-form-select-option :value="null" disabled>-- Select a workshop --</b-form-select-option>
					</template>
				</b-form-select>
			</b-form-group>

			<h3>Billing</h3>

			<b-row>
				<b-col lg>
					<b-form-group label="Billing Name" :invalid-feedback="requiredFeedback($v.form.billingName)">
						<b-form-input v-model.trim="$v.form.billingName.$model" type="text" :state="getValidState($v.form.billingName)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col lg>
					<b-form-group label="Billing Email" :invalid-feedback="requiredFeedback($v.form.billingEmail)">
						<b-form-input v-model.trim="$v.form.billingEmail.$model" type="email" :state="getValidState($v.form.billingEmail)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col lg>
					<b-form-group label="Fees Paid" :invalid-feedback="requiredFeedback($v.form.feesPaid)">
						<b-form-input v-model.trim="$v.form.feesPaid.$model" type="number" step="any" :state="getValidState($v.form.feesPaid)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col lg>
					<b-row>
						<b-col lg="8">
							<b-form-group label="Fees Due" :invalid-feedback="requiredFeedback($v.form.feesDue)">
								<b-form-input v-model.trim="$v.form.feesDue.$model" type="number" step="any" :state="getValidState($v.form.feesDue)"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col lg>
							<b-form-group label="Currency" :invalid-feedback="requiredFeedback($v.form.feesDueCurrency)">
								<b-form-input v-model.trim="$v.form.feesDueCurrency.$model" type="text" :state="getValidState($v.form.feesDueCurrency)"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			<b-row>
				<b-col lg>
					<b-form-group label="Order Number" :invalid-feedback="requiredFeedback($v.form.orderNumber)">
						<b-form-input v-model.trim="$v.form.orderNumber.$model" type="text" :state="getValidState($v.form.orderNumber)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group label="Registration Date" :invalid-feedback="requiredFeedback($v.form.registrationDate)">
						<b-form-input type="datetime-local" v-model.trim="$v.form.registrationDate.$model" :state="getValidState($v.form.registrationDate)" required></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col lg>
					<b-form-group label="Workshop Registration Type" :invalid-feedback="requiredFeedback($v.form.workshopRegistrationType)">
						<b-form-input v-model.trim="$v.form.workshopRegistrationType.$model" type="text" :state="getValidState($v.form.workshopRegistrationType)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<h3>Attendee</h3>

			<b-row>
				<b-col lg>
					<b-form-group label="Attendee Name" :invalid-feedback="requiredFeedback($v.form.attendeeName)">
						<b-form-input v-model.trim="$v.form.attendeeName.$model" type="text" :state="getValidState($v.form.attendeeName)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col lg>
					<b-form-group label="Attendee Email" :invalid-feedback="requiredFeedback($v.form.attendeeEmail)">
						<b-form-input v-model.trim="$v.form.attendeeEmail.$model" type="email" :state="getValidState($v.form.attendeeEmail)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col lg>
					<b-form-group label="Attendee Organization" :invalid-feedback="requiredFeedback($v.form.attendeeOrganization)">
						<b-form-input v-model.trim="$v.form.attendeeOrganization.$model" type="text" :state="getValidState($v.form.attendeeOrganization)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col lg>
					<b-form-group label="Attendee Country" :invalid-feedback="requiredFeedback($v.form.attendeeCountry)">
						<b-form-input v-model.trim="$v.form.attendeeCountry.$model" type="text" :state="getValidState($v.form.attendeeCountry)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Attendee Needs/Requests" :invalid-feedback="requiredFeedback($v.form.attendeeNeeds)">
				<b-form-textarea v-model.trim="$v.form.attendeeNeeds.$model" :state="getValidState($v.form.attendeeNeeds)" rows="3"></b-form-textarea>
			</b-form-group>

			<b-form-group label="Additional Information" :invalid-feedback="requiredFeedback($v.form.additionalInformation)">
				<b-form-textarea v-model.trim="$v.form.additionalInformation.$model" :state="getValidState($v.form.additionalInformation)" rows="5"></b-form-textarea>
			</b-form-group>

			<div>
				<b-checkbox v-model.trim="$v.form.noCertificate.$model">Diable certificate / set as not attending?</b-checkbox>
			</div>

			<fixed-action-bar :cols-lg="10" :offset-lg="2">
				<save-button :saving="page.saving" class="mr-2" :disabled="!$v.$anyDirty" />
				<back-button class="btn btn-secondary mr-2" />
				<save-button :saving="page.saving" @click.native="sendEmail" class="mr-2" text="Send Invoice Email" variant="info" />

				<a v-if="isUpdate" :href="`/data/workshopreceipt/${form.workshopId}?order=${orderUrl}`" class="btn btn-info mr-2" target="_blank">
					Get Receipt
					<font-awesome-icon :icon="['fas', 'file-pdf']" fixed-width class="mr-2" />
				</a>

				<a v-if="isUpdate" :href="`/data/workshopcertificate/${form.workshopId}?order=${orderUrl}`" class="btn btn-info mr-2" target="_blank">
					Get Certificate
					<font-awesome-icon :icon="['fas', 'file-pdf']" fixed-width class="mr-2" />
				</a>

				<b-button v-if="isUpdate" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete this registration?
				This action is permanent and cannot be undone.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</auth-container>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';

	export default {
		name: 'WorkshopRegistrationForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			id: {
				type: String,
				default: null
			}
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: [],
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				form: {
					workshopId: null,
					registrationDate: null,
					orderNumber: null,
					billingName: null,
					billingEmail: null,
					feesPaid: 0,
					feesDue: 0,
					feesDueCurrency: null,
					attendeeName: null,
					attendeeEmail: null,
					attendeeOrganization: null,
					attendeeCountry: null,
					attendeeNeeds: null,
					workshopRegistrationType: null,
					additionalInformation: null,
					noCertificate: false
				},
				options: {
					workshops: []
				},
				orderUrl: null
			}
		},
		validations: {
			form: {
				workshopId: { required },
				registrationDate: { required },
				orderNumber: {},
				billingName: {},
				billingEmail: {},
				feesPaid: { required },
				feesDue: { required },
				feesDueCurrency: {},
				attendeeName: { required },
				attendeeEmail: { required },
				attendeeOrganization: {},
				attendeeCountry: {},
				attendeeNeeds: {},
				workshopRegistrationType: {},
				additionalInformation: {},
				noCertificate: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				try {
					const response = await this.$http.get(`workshops/list`, this.getTokenHeader());
					this.log(response.data);
					this.options.workshops = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				if (this.isUpdate) {
					this.page.errors = [];
					this.page.loading = true;

					try {
						const response = await this.$http.get(`workshopregistrations/${this.id}`, this.getTokenHeader());
						this.log(response.data);
						this.form = response.data.registration;
						this.form.registrationDate = this.dateTimeForForm(this.form.registrationDate);
						this.orderUrl = this.form.orderNumber.replace('#', '');
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}

					this.page.loading = false;
				}
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						if (!this.isUpdate) {
							await this.$http.post('workshopregistrations', this.form, this.getTokenHeader());
							this.$router.push({ name: 'WorkshopRegistrationList' }).catch(err => { this.log(err); });
						} else {
							await this.$http.put(`workshopregistrations/${this.id}`, this.form, this.getTokenHeader());
							this.$refs.savedAlert.startAlert();
							this.$v.$reset();
						}
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`workshopregistrations/${this.id}`, this.getTokenHeader());
					this.log(response.data);
					this.$router.push({ name: 'WorkshopRegistrationList' }).catch(err => { this.log(err); });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			async sendEmail() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					await this.$http.post(`workshopregistrations/sendemail/${this.id}`, {}, this.getTokenHeader());
					this.$refs.emailAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			}
		}
	}
</script>