<template>
	<div>
		<auth-container :page="page">
			<h2 class="mb-4">Edit abstract</h2>
			<error-list :errors="page.saveErrors"></error-list>
			
			<b-toast id="changes-saved" variant="success" solid title="Changes saved">
				The abstract has been updated.
			</b-toast>

			<div class="abstract-edit-form">
				<h2 class="h4">Scheduling</h2>

				<b-form-radio-group v-model.trim="$v.data.paper.isPoster.$model" buttons button-variant="outline-primary" size="md" class="present-formats mb-3">
					<b-form-radio :value="false"><font-awesome-icon v-if="!data.paper.isPoster" :icon="['fas', 'check']" /> Oral</b-form-radio>
					<b-form-radio :value="true"><font-awesome-icon v-if="data.paper.isPoster" :icon="['fas', 'check']" /> Poster</b-form-radio>
				</b-form-radio-group>

				<b-form-radio-group v-model="$v.data.paper.isRemote.$model" buttons button-variant="outline-primary" size="md" class="present-formats mb-3">
					<b-form-radio :value="false"><font-awesome-icon v-if="!data.paper.isRemote" :icon="['fas', 'check']" /> In-Person</b-form-radio>
					<b-form-radio :value="true"><font-awesome-icon v-if="data.paper.isRemote" :icon="['fas', 'check']" /> Remote</b-form-radio>
				</b-form-radio-group>

				<b-form-group>
					<b-checkbox v-model.trim="$v.data.paper.isRegistered.$model">Is the presenter registered?</b-checkbox>
				</b-form-group>
				<b-form-group>
					<b-checkbox v-model.trim="$v.data.paper.hasRegistrationExtension.$model">Grant a registration extension?</b-checkbox>
				</b-form-group>
				<b-form-group>
					<b-checkbox v-model.trim="$v.data.paper.isScheduleConfirmed.$model">Did the presenter confirm their schedule?</b-checkbox>
				</b-form-group>

				<b-row>
					<b-col md>
						<b-form-group label="Session" :invalid-feedback="requiredFeedback($v.data.paper.sessionId)">
							<b-form-select :options="data.sessions" v-model.trim="$v.data.paper.sessionId.$model" type="text" :state="getValidState($v.data.paper.sessionId)">
								<template v-slot:first>
									<b-form-select-option :value="null" disabled>-- Select a session --</b-form-select-option>
								</template>
							</b-form-select>
						</b-form-group>
					</b-col>
					<b-col md>
						<b-form-group label="Order in session" :invalid-feedback="requiredFeedback($v.data.paper.orderInSession)">
							<b-form-input v-model.number.trim="$v.data.paper.orderInSession.$model" type="number" :state="getValidState($v.data.paper.orderInSession)"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>

				<b-form-group label="Scheduling restrictions" :invalid-feedback="requiredFeedback($v.data.paper.scheduleRequests)">
					<b-form-textarea rows="3" v-model.trim="$v.data.paper.scheduleRequests.$model" :state="getValidState($v.data.paper.scheduleRequests)"></b-form-textarea>
				</b-form-group>

				<hr class="my-4" />

				<h2 class="h4">Presenter</h2>
				<b-row>
					<b-col md>
						<b-form-group label="First name (given name)" :invalid-feedback="requiredFeedback($v.data.paper.presenterFirstName)">
							<b-form-input autofocus v-model.trim="$v.data.paper.presenterFirstName.$model" type="text" :state="getValidState($v.data.paper.presenterFirstName)"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col md>
						<b-form-group label="Last name (family/surname)" :invalid-feedback="requiredFeedback($v.data.paper.presenterLastName)">
							<b-form-input v-model.trim="$v.data.paper.presenterLastName.$model" type="text" :state="getValidState($v.data.paper.presenterLastName)"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col md>
						<b-form-group label="Email address">
							<b-form-input v-model.trim="$v.data.paper.presenterEmail.$model" type="email" :state="getValidState($v.data.paper.presenterEmail)"></b-form-input>
							<b-form-invalid-feedback v-if="!$v.data.paper.presenterEmail.required">Required</b-form-invalid-feedback>
							<b-form-invalid-feedback v-if="!$v.data.paper.presenterEmail.email">Please enter a valid email address</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col md>
						<b-form-group label="Affiliation (organization or university)" :invalid-feedback="requiredFeedback($v.data.paper.presenterAffiliation)">
							<b-form-input v-model.trim="$v.data.paper.presenterAffiliation.$model" type="text" :state="getValidState($v.data.paper.presenterAffiliation)"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>

				<hr class="my-4" />

				<h2 class="h4">Authors</h2>

				<p>
					Please add all authors for your paper in the correct <abbr title="Click and drag a row to sort">order</abbr>, including the presenter above if applicable.
					(To remove, just clear the text from the blanks.)
				</p>

				<p>
					CA = Corresponding author; email address is required for the corresponding author, but optional for other authors.
				</p>

				<table class="table table-striped table-sm table-responsive table-valign-m">
					<thead class="thead-light">
						<tr>
							<th></th>
							<th><abbr title="Corresponding author">CA</abbr></th>
							<th>First name (given name)</th>
							<th>Last name (family/surname)</th>
							<th style="width:20rem">Email (required for CA)</th>
							<th style="width:25rem">Affiliation (optional)</th>
						</tr>
					</thead>
					<draggable v-model="data.paper.authors" tag="tbody">
						<tr v-for="(a, i) in data.paper.authors" :key="i">
							<td><font-awesome-icon :icon="['fas', 'sort']" fixed-width /></td>
							<td><b-checkbox v-model="a.isCorresponding"></b-checkbox></td>
							<td><b-form-input v-model="a.firstName" type="text"></b-form-input></td>
							<td><b-form-input v-model="a.lastName" type="text"></b-form-input></td>
							<td><b-form-input v-model="a.email" type="email"></b-form-input></td>
							<td><b-form-input v-model="a.affiliation" type="text"></b-form-input></td>
						</tr>
					</draggable>
				</table>

				<div>
					<b-button type="button" @click="addAuthor" variant="primary">Add an author</b-button>
				</div>

				<hr class="my-4" />

				<h2 class="h4 mb-3">Paper information</h2>

				<b-form-group :invalid-feedback="requiredFeedback($v.data.paper.topicId)">
					<b-form-select :options="data.topics" v-model.trim="$v.data.paper.topicId.$model" type="text" :state="getValidState($v.data.paper.topicId)">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>-- Select a topic for your paper --</b-form-select-option>
						</template>
					</b-form-select>
				</b-form-group>

				<b-form-group label="Title" :invalid-feedback="requiredFeedback($v.data.paper.title)">
					<b-form-input v-model.trim="$v.data.paper.title.$model" type="text" :state="getValidState($v.data.paper.title)"></b-form-input>
				</b-form-group>

				<b-form-group label="Type or paste your abstract" :invalid-feedback="requiredFeedback($v.data.paper.abstract)">
					<tinymce-editor :api-key="tinymce.api" :init="tinymce.init" v-model.trim="$v.data.paper.abstract.$model" :state="getValidState($v.data.paper.abstract)"></tinymce-editor>
					<b-form-invalid-feedback v-if="!$v.data.paper.abstract.required">Required</b-form-invalid-feedback>
				</b-form-group>

				<b-form-group label="Keywords (optional)" :invalid-feedback="requiredFeedback($v.data.paper.keywords)">
					<b-form-input v-model.trim="$v.data.paper.keywords.$model" type="text" :state="getValidState($v.data.paper.keywords)"></b-form-input>
				</b-form-group>
			</div>

			<fixed-action-bar :cols-lg="10" :offset-lg="2">
				<save-button :saving="page.saving" @click.native="save" class="mr-2" />
				<back-button class="btn btn-secondary mr-2" />				
			</fixed-action-bar>
		</auth-container>
	</div>
</template>

<script>
	import { required, integer, email } from 'vuelidate/lib/validators';
	import draggable from 'vuedraggable';
	import Editor from '@tinymce/tinymce-vue';

	export default {
		name: 'PaperEdit',
		components: {
			draggable, 'tinymce-editor': Editor
		},
		data() {
			return {
				confId: this.$route.params.conf,
				id: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: []
				},
				data: {
					paper: {
						id: null,
						conferenceId: null,
						accessCode: null,
						dateSubmitted: null,
						presenterFirstName: null,
						presenterLastName: null,
						presenterEmail: null,
						presenterAffiliation: null,
						topicId: null,
						title: null,
						authors: [
							{
								firstName: null,
								lastName: null,
								email: null,
								affiliation: null,
								isCorresponding: false
							}
						],
						abstract: null,
						keywords: null,
						isPoster: false,
						isRemote: false,
						scheduleRequests: null,
						status: null,
						reviewedDate: null,
						reviewer: null,
						reviewerNotes: null,
						sessionId: null,
						orderInSession: null,
						isScheduleConfirmed: false,
						isRegistered: false,
						hasRegistrationExtension: false
					},
					topics: [],
					sessions: []
				},
				options: {
					formats: [
						{ text: 'Oral', value: false },
						{ text: 'Poster', value: true }
					]
				},
				tinymce: {
					api: 'umc6fcf2lmc4vpzyrqb2jfcr2slzvqb94kk4ac6wl757gx5b',
					init: {
						height: 400,
						menubar: false,
						plugins: [
							'advlist autolink lists link image charmap print preview anchor',
							'searchreplace visualblocks code fullscreen',
							'insertdatetime media table paste code help wordcount'
						],
						toolbar: 'undo redo | formatselect | bold italic underline | link unlink | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code',
					}
				}
			}
		},
		validations: {
			data: {
				paper: {
					id: { required },
					conferenceId: { required },
					accessCode: { required },
					dateSubmitted: { required },
					presenterFirstName: { required },
					presenterLastName: { required },
					presenterEmail: { required, email },
					presenterAffiliation: { required },
					topicId: { required, integer },
					title: { required },
					abstract: { required },
					keywords: {},
					isPoster: {},
					isRemote: {},
					scheduleRequests: {},
					status: { required },
					reviewedDate: {},
					reviewer: {},
					reviewerNotes: {},
					sessionId: {},
					orderInSession: { required, integer},
					isScheduleConfirmed: {},
					isRegistered: {},
					hasRegistrationExtension: {}
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.confId = this.$route.params.conf;
				this.id = this.$route.params.id;

				try {
					const response = await this.$http.get(`papers/edit/${this.confId}/${this.id}`, this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.data.paper.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
					this.log(this.$v);
				} else {
					try {
						const response = await this.$http.put(`papers/edit/${this.id}`, this.data.paper, this.getTokenHeader());
						this.log(response.data);
						this.$bvToast.show('changes-saved');
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			addAuthor() {
				this.data.paper.authors.push({
					firstName: null,
					lastName: null,
					email: null,
					affiliation: null,
					isCorresponding: false
				});
			}
		}
	}
</script>