<template>
	<div>
		<auth-container v-if="$route.name === 'UserList'" :page="page" require-admin>
			<h2 class="mb-4">Users</h2>

			<grid-view ref="userTable" api-url="users" use-filter
					   :fields="table.fields"
					   :default-sort="table.sort" :default-reverse="table.reverse" :default-per-page="table.itemsPerPage"
					   collection-description="users" show-create
					   item-name="user"></grid-view>

			<hr class="my-4" />
			<h3 class="my-4">Upload new users</h3>

			<p>
				Upload a csv of users with the following columns (headers on first line):
				<code>
					email, firstName, lastName, affiliation, country, inAllByDefault
				</code>
			</p>

			<error-list :errors="page.saveErrors"></error-list>
			<success-alert ref="savedAlert" text="Changes saved." />

			<vue-dropzone ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';

	export default {
		name: 'UserList',
		components: {
			GridView, vueDropzone: vue2Dropzone
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: []
				},
				dropzoneOptions: {
					url: '/api/users/upload/',
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.csv'
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'isAdmin', label: '' },
						{ key: 'email', sortable: true },
						{ key: 'firstName', sortable: true },
						{ key: 'lastName', sortable: true },
						{ key: 'affiliation', sortable: true },
						{ key: 'country', sortable: true },
						{ key: 'lastLoginDate', label: 'Last Log-in', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY h:mm a') } },
						{ key: 'registrationDate', label: 'Registered', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY') } },
						{ key: 'isLockedOut', label: 'Locked?', sortable: true }
					],
					sort: 'lastName',
					reverse: false,
					itemsPerPage: 50
				}
			}
		},
		methods: {
			async fileUploaded(file, response) {
				this.log(file);
				this.log(response);
				this.page.saveErrors = [];
				await this.$refs.userTable.get();
				this.$refs.savedAlert.startAlert();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.saveErrors.push(message);
			}
		}
	}
</script>