<template>
	<registration-form is-update :id="id"></registration-form>
</template>

<script>
	import RegistrationForm from '@/views/manage/registrations/RegistrationForm';

	export default {
		name: 'RegistrationCreate',
		components: {
			RegistrationForm
		},
		data() {
			return {
				id: this.$route.params.id
			}
		}
	}
</script>