<template>
	<div id="content-frame">
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<div class="bg-gradient-light-blue py-5">
				<b-container>
					<h1>SWAT Conference Abstract Submission Website</h1>
					<p class="lead">
						This site handles abstract submissions for Soil and Water Assessment Tool (SWAT) Conferences.
						If you are looking for information about the SWAT model, please visit <a href="https://swat.tamu.edu">swat.tamu.edu</a>.
					</p>
				</b-container>
			</div>
			<div class="py-5">
				<b-container>
					<div v-if="conferences.length > 0">
						<h2 class="mb-3">Open Calls for Papers</h2>

						<ul class="no-bullet biglist">
							<li v-for="(c, i) in conferences" :key="i">
								<router-link :to="`/submit-abstract/${c.id}`">{{c.name}}</router-link>
							</li>
						</ul>

						<h2 class="mt-5 mb-3">Join Our Mailing List</h2>
					</div>

					<b-row>
						<b-col md="4">
							<p>
								Receive weekly to monthly email about upcoming SWAT conferences, workshops, call for papers, software updates, and more.
								Email is not typically sent more than once a week, and less frequently when a conference is not approaching.
							</p>
						</b-col>
						<b-col>
							<!-- Begin MailChimp Signup Form -->
							<div id="mc_embed_signup">
								<form action="//tamu.us8.list-manage.com/subscribe/post?u=8fcce08607bf4838baf1aa9d9&amp;id=7a5b5e9cfb" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
									<div id="mc_embed_signup_scroll">
										<div class="mc-field-group form-group">
											<label for="mce-EMAIL">
												Email Address  (required)
											</label>
											<input type="email" value="" name="EMAIL" class="required email form-control" id="mce-EMAIL">
										</div>
										<div class="mc-field-group form-group">
											<label for="mce-FNAME">First Name </label>
											<input type="text" value="" name="FNAME" class="form-control" id="mce-FNAME">
										</div>
										<div class="mc-field-group form-group">
											<label for="mce-LNAME">Last Name </label>
											<input type="text" value="" name="LNAME" class="form-control" id="mce-LNAME">
										</div>
										<div id="mce-responses" class="clear">
											<div class="response" id="mce-error-response" style="display:none"></div>
											<div class="response" id="mce-success-response" style="display:none"></div>
										</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
										<div style="position: absolute; left: -5000px;"><input type="text" name="b_8fcce08607bf4838baf1aa9d9_7a5b5e9cfb" tabindex="-1" value=""></div>
										<div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="btn btn-primary"></div>
									</div>
								</form>
							</div>
							<!--End mc_embed_signup-->
						</b-col>
					</b-row>
				</b-container>
			</div>
			<footer-data></footer-data>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Home',
		data() {
			return {
				page: {
					errors: [],
					loading: false
				},
				conferences: []
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`conferences/open`);
					this.log(response.data);
					this.conferences = response.data;
				} catch (error) {
					this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		}
	}
</script>
