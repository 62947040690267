<template>
	<regparser-form is-update :id="id"></regparser-form>
</template>

<script>
	import RegparserForm from '@/views/manage/regparsers/RegparserForm';

	export default {
		name: 'RegparserCreate',
		components: {
			RegparserForm
		},
		data() {
			return {
				id: this.$route.params.id
			}
		}
	}
</script>