<template>
	<div id="content-frame">
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<error-list v-if="page.errors.length > 0" :errors="page.errors"></error-list>
			<b-container v-else class="py-5">
				<h2>Certificates for the {{data.conference}} - {{data.name}}</h2>
				<p>
					Find your certificates below. If you used more than one email address in our system, it's possible some certificates are linked to another email address.
					Please check all possible email accounts and links sent to you.
					Report any errors to <a :href="`mailto:${contactEmail}`">{{contactEmail}}</a> as soon as possible.
				</p>

				<ul>
					<li v-for="(c, i) in data.certificates" :key="i">
						<a v-if="c.type === 1" :href="`/data/certificate/${id}?type=${c.type}&paperId=${c.paperId}`" target="_blank">{{c.name}}</a>
						<a v-else :href="`/data/certificate/${id}?type=${c.type}`" target="_blank">{{c.name}}</a>
					</li>
				</ul>
			</b-container>
		</div>
		<footer-data></footer-data>
	</div>
</template>

<script>
	export default {
		name: 'GetCertificates',
		data() {
			return {
				id: this.$route.params.id,
				page: {
					errors: [],
					loading: false
				},
				data: {
					name: null,
					conference: null,
					certificates: []
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.id = this.$route.params.id;

				try {
					const response = await this.$http.get(`certificates/list/${this.id}`);
					this.log(response.data);
					this.data = response.data;

				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		}
	}
</script>
